import * as StorageUtil from './storage';


const CUSTOMER_KEY = 'customer';

export function clearCustomer() {
    StorageUtil.setItem(CUSTOMER_KEY, '{}');
}

export function getCustomer() {
    return JSON.parse(StorageUtil.getItem(CUSTOMER_KEY) || '{}');
}

export function setCustomer(user) {
    StorageUtil.setItem(CUSTOMER_KEY, JSON.stringify(user));
}
