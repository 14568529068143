import axios from 'axios';
import { BASE_URL } from '../util/constants';

export function login({username, password}) {
  const auth = {
    password: password,
    userName: username
  }
  return axios.post(`${BASE_URL}/user/authenticate`, auth);
}

export function getUserDetails(){
    return axios.get(`${BASE_URL}/user/details`);
}

export function register(values) {
  return axios.post(`${BASE_URL}/user/register`, values);
}

export function update(values) {
  return axios.post(`${BASE_URL}/user/update`, values)
}

export function _delete() {
  return axios.post(`${BASE_URL}/user/delete`)
}

export function resetPassword(values) {
  return axios.post(`${BASE_URL}/user/password/reset`, values);
}

export function updatePassword(password, token) {
  return axios.put(`${BASE_URL}/user/password`, {password, token});
}

export function getOrderHistory(params) {
  return axios.get(`${BASE_URL}/user/sales`, {params: {...params.params, fe_active: true}});
}

export function getVehicles() {
  return axios.get(`${BASE_URL}/vehicles`);
}

export function createVehicle(vehicle) {
  return axios.post(`${BASE_URL}/vehicles/create`, vehicle);
}

export function deleteVehicles(idList) {
  return axios.post(`${BASE_URL}/vehicles/delete`, { idList });
}

export function userConfirm(token){
  const params = {token};
  return axios.get(`${BASE_URL}/user/confirm`, { params });
}

export function refreshToken() {
  return axios.get(`${BASE_URL}/user/authentication/refresh`);
}