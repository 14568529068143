import React from 'react';
import Link from '../../../components/LinkLng';
import { useUserContext } from '../../../contexts/userContext';
import { useLanguageContext } from '../../../contexts/languageContext';
import { useVignetteContext } from '../../../contexts/vignetteContext';

export default function UserDropdown() {
  const { user, setUser } = useUserContext();
  const { gettext } = useLanguageContext();
  const { clearVignettes } = useVignetteContext();

  const logOut = () => {
    clearVignettes();
    setUser({});
  };

  return (
    <nav className="site-login">
      <div onClick={logOut} className="menu-icon menu-icon-profile icon icon-user-outline icon-white" />
      <ul className="menu-links">
        <li className="menu-item dropdown-container">
          <Link to="/account/orderHistory">{`${user.firstName} ${user.lastName}`}</Link>
          <ul className="menu-dropdown">
            <div className="menu-item" onClick={logOut}>{gettext('Изход')}</div>
          </ul>
        </li>
      </ul>
    </nav>
  );
}