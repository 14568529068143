import React, { Fragment, useState, useRef, useContext } from 'react';
import ClickOutside from '../components/ClickOutside';

export const MenuContext = React.createContext();
export const useMenuContext = () => useContext(MenuContext);

export function MenuProvider(props) {
  const [openState, setOpenState] = useState(false);
  const [coordinates, setCoordinates] = useState({x: 0, y: 0});
  const [items, setItems] = useState();
  const awaitingPromiseRef = useRef();


  const getMenuPostion = (event, contextMenu) => {
      const menuDimensionX = 300;//contextMenu.outerWidth();
      const menuDimensionY = 150;//contextMenu.outerHeight();

      return {
        x: event.pageX + menuDimensionX > document.documentElement.clientWidth ? event.pageX - menuDimensionX : event.pageX,
        y: event.pageY + menuDimensionY > document.documentElement.clientHeight ? event.pageY - menuDimensionY : event.pageY
      }
  };

  const menuOpen = (event, items) => {
    setItems(items);
    setOpenState(true);
    setCoordinates(getMenuPostion(event));
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const menuClose = data => {
    //awaitingPromiseRef.current && awaitingPromiseRef.current.reject(data);
    awaitingPromiseRef.current = null;
    setOpenState(false);
  };

  // const menuSubmit = data => {
  //   awaitingPromiseRef.current && awaitingPromiseRef.current.resolve(data);
  //   awaitingPromiseRef.current = null;
  //   setOpenState(false);
  // };

  const menuStyle = {
    visibility: openState ? 'visible' : 'hidden',
    left: coordinates.x,
    top: coordinates.y
  };

  return (
    <MenuContext.Provider value={{ menuOpen, menuClose }}>
      <Fragment>
        {props.children}
        <ClickOutside onClickOutside={menuClose}>
          <ul className="custom-context-menu" style={menuStyle}>
            {items}
          </ul>
        </ClickOutside>
      </Fragment>
    </MenuContext.Provider>
  );
}