import axios from 'axios';
import { BASE_URL } from '../../util/constants';

export function register(values) {
  return axios.post(`${BASE_URL}/user/register`, values);
}

export function getRoles() {
  return axios.get(`${BASE_URL}/roles`).then(data => data.data.elements);
}

export function getAllUsers(params) {
  return axios.get(`${BASE_URL}/user/all`, params);
}

export function userDeactivate(userId) {
  return axios.delete(`${BASE_URL}/user/decommission/${userId}`);
}

export function userDelete(userId) {
  return axios.delete(`${BASE_URL}/user/delete/${userId}`);
}

export function userActivate(userId) {
  return axios.post(`${BASE_URL}/user/activate/${userId}`);
}

export function userUpdate(userData) {
  return axios.post(`${BASE_URL}/user/update/admin`, userData);
}

export function userPasswordChange(data) {
  return axios.post(`${BASE_URL}/user/update/password/admin`, data);
}