const ENV = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV.toUpperCase() : 'DEV';
const TYPE = process.env.REACT_APP_TYPE ? process.env.REACT_APP_TYPE.toUpperCase() : '';
export const IS_PROD_ENV = ['PROD', 'TOLLPROD'].includes(process.env.REACT_APP_ENV);
export const CAPTCHA = "6LcIWmQjAAAAAGFOIhNxFhmV19_tKPsxwKfy_c7c";
export const CAPTCHA_EMAILS = "6LejDDImAAAAAPgwSKsXizjunnypGlJ_EXv5m9Eb";

const CONF = {
	DEV: {

		BACKEND_URL: 'https://rest.dev.digitoll.bg',
		// BACKEND_URL: 'http://localhost:8081',
		// BACKEND_URL: 'https://restpub.dev.digitoll.bg',
		KAPSH_MAP_KEY: 'vz7aBBBJ2p9eZzVcxhCQ',
		GTAG_ID: 'G-P3H4R7SJFW',
		GTM_ID: 'GTM-PQBQJSP',
		BORIKA_ACTION: 'https://3dsgate-dev.borica.bg/cgi-bin/cgi_link'
	},
	DEVSC: {
		BACKEND_URL: 'https://sc-rest.dev.digitoll.bg',
		// override the maximum number of results that the user will be able 
		// to download as a CSV report, default is 5000
		// CSV_MAX_LENGTH: 5000,
		KAPSH_MAP_KEY: 'vz7aBBBJ2p9eZzVcxhCQ',
		BORIKA_ACTION: 'https://3dsgate-dev.borica.bg/cgi-bin/cgi_link'
	},
	PROD: {
		BACKEND_URL: 'https://rest.digitoll.bg',
		LOG_ERRORS: true,
		KAPSH_MAP_KEY: 'PBaW9YPttD0ddi112AOS',
		GTAG_ID: 'G-P3H4R7SJFW',
		GTM_ID: 'GTM-PQBQJSP',
		BORIKA_ACTION: 'https://3dsgate.borica.bg/cgi-bin/cgi_link'
	},
	ERPTABLETPROD: {
		BACKEND_URL: 'https://petrol.digitoll.bg',
		LOG_ERRORS: true,
		ENABLE_ROUTE_PASS: true,
		KAPSH_MAP_KEY: 'PBaW9YPttD0ddi112AOS',
		BORIKA_ACTION: 'https://3dsgate.borica.bg/cgi-bin/cgi_link'
	},
	ERPDEV:{
		BACKEND_URL: 'https://erp.dev.digitoll.bg',
		// BACKEND_URL: 'http://localhost:8081',
		LOCAL_WINDOWS_SERVICE_URL: 'http://localhost:9000',
		ENABLE_ROUTE_PASS: true,
		ENABLE_VIGNETTE: true,
		KAPSH_MAP_KEY: 'vz7aBBBJ2p9eZzVcxhCQ',
		BORIKA_ACTION: 'https://3dsgate-dev.borica.bg/cgi-bin/cgi_link'
	},
	ERPTABLETDEV:{
		BACKEND_URL: 'https://petrol.dev.digitoll.bg',
		ENABLE_ROUTE_PASS: true,
		KAPSH_MAP_KEY: 'vz7aBBBJ2p9eZzVcxhCQ',
		BORIKA_ACTION: 'https://3dsgate-dev.borica.bg/cgi-bin/cgi_link'
	},
	ERPACC:{
		BACKEND_URL: 'https://erp.acc.digitoll.bg',
		ENABLE_ROUTE_PASS: true,
		ENABLE_VIGNETTE: true,
		KAPSH_MAP_KEY: 'vz7aBBBJ2p9eZzVcxhCQ',
		BORIKA_ACTION: 'https://3dsgate-dev.borica.bg/cgi-bin/cgi_link'
	},
	ERPPROD:{
		BACKEND_URL: 'https://erp.digitoll.bg',
		LOCAL_WINDOWS_SERVICE_URL: 'http://localhost:9000',
		LOG_ERRORS: true,
		ENABLE_ROUTE_PASS: true,
		ENABLE_VIGNETTE: true,
		KAPSH_MAP_KEY: 'PBaW9YPttD0ddi112AOS',
		BORIKA_ACTION: 'https://3dsgate.borica.bg/cgi-bin/cgi_link'
	},
	LOC:{
		//BACKEND_URL: 'https://localhost',
		ENABLE_ROUTE_PASS: true,
		ENABLE_VIGNETTE: true,
		BACKEND_URL: 'http://localhost:8080',
		LOCAL_WINDOWS_SERVICE_URL: 'http://localhost:9000',
		KAPSH_MAP_KEY: 'vz7aBBBJ2p9eZzVcxhCQ',
		BORIKA_ACTION: 'https://3dsgate-dev.borica.bg/cgi-bin/cgi_link'
		//BACKEND_URL: 'http://192.168.0.228:8080'
	},
	ERPLOC:{
		BACKEND_URL: 'http://localhost:8081',
		// ENABLE_TOLLING: true,
		KAPSH_MAP_KEY: 'vz7aBBBJ2p9eZzVcxhCQ',
		ENABLE_ROUTE_PASS: true,
		ENABLE_VIGNETTE: true,
		BORIKA_ACTION: 'https://3dsgate-dev.borica.bg/cgi-bin/cgi_link'
		// LOCAL_WINDOWS_SERVICE_URL: 'http://localhost:9000'
	},
	ERPTOLL: {
		BACKEND_URL: 'https://erp.acc.digitoll.bg',
		KAPSH_MAP_KEY: 'vz7aBBBJ2p9eZzVcxhCQ',
		BORIKA_ACTION: 'https://3dsgate-dev.borica.bg/cgi-bin/cgi_link'
	},
	ERPTOLLACC: {
		BACKEND_URL: 'https://erp.acc.toll.digitoll.bg',
		ENABLE_TOLLING: true,
		KAPSH_MAP_KEY: 'vz7aBBBJ2p9eZzVcxhCQ',
		BORIKA_ACTION: 'https://3dsgate-dev.borica.bg/cgi-bin/cgi_link'
	},
	ERPTOLLDEV: {
		BACKEND_URL: 'https://erp.dev.toll.digitoll.bg',
		// BACKEND_URL: 'http://localhost:8081',
		ENABLE_TOLLING: true,
		KAPSH_MAP_KEY: 'vz7aBBBJ2p9eZzVcxhCQ',
		BORIKA_ACTION: 'https://3dsgate-dev.borica.bg/cgi-bin/cgi_link'
	},
	ERPTOLLTEST: {
		BACKEND_URL: 'https://erp.test.toll.digitoll.bg',
		// BACKEND_URL: 'http://localhost:8081',
		ENABLE_TOLLING: true,
		KAPSH_MAP_KEY: 'vz7aBBBJ2p9eZzVcxhCQ',
		BORIKA_ACTION: 'https://3dsgate-dev.borica.bg/cgi-bin/cgi_link'
	},
	ERPTOLLLOC: {
		BACKEND_URL: 'http://localhost:8081',
		ENABLE_TOLLING: true,
		KAPSH_MAP_KEY: 'vz7aBBBJ2p9eZzVcxhCQ',
		BORIKA_ACTION: 'https://3dsgate-dev.borica.bg/cgi-bin/cgi_link'
	},
	ERPTOLLPROD: {
		BACKEND_URL: 'https://erp.toll.digitoll.bg',
		ENABLE_TOLLING: true,
		KAPSH_MAP_KEY: 'PBaW9YPttD0ddi112AOS',
		BORIKA_ACTION: 'https://3dsgate.borica.bg/cgi-bin/cgi_link'
	}
};

if (!CONF[TYPE+ENV]) {
	// here we break the app because the environment variables are wrong
	throw new Error(`Missing config ${TYPE}${ENV}!`);
}

export const CONFIG = CONF[TYPE+ENV];
