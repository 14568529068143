import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { getUser, clearUser } from '../../util/user';
import { clearCustomer } from '../../util/customer';
import { getLang, getPathWithLang, urlHasLang, getWindowLocation } from '../../util/language';
import { getUserDetails } from '../../services/userService';
import { updateMeta } from '../../util/meta';
import { BASE_URL } from '../../util/constants';
import { CONFIG } from '../../config';
import { Link } from 'react-router-dom';
import RoadSeparator from '../../components/RoadSeparator';

export default withRouter(class ErrorBoundary extends Component {

  state = {
    hasError: false,
  };

  updateRoute() {
    const language = getLang();
    const path = getPathWithLang();
    if (getWindowLocation() !== path) {
      this.props.history.replace(path);
    }
    updateMeta(path, language);
  }

  componentDidUpdate() {
    this.updateRoute();
  }

  componentDidMount() {
    this.updateRoute();
    const language = getLang();

    axios.interceptors.request.use((config) => {
      const userData = getUser();
      const language = getLang();
      if (!config.params) {
        config.params = {};
      }
      config.params['browser-language'] = language;
      config.headers['Accept-Language'] = language;
      if (userData.token) {
        config.headers['Authorization'] = `Bearer ${userData.token}`;
      }
      return config;
    }, (error) => {
      return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {
      return response;
    }, (error) => {

      if (error.response && error.response.status === 401) {
        clearUser();
        clearCustomer();
        this.props.history.push(`/${language}/login`);
      }

      return Promise.reject(error);
    });

    // handling first time page open
    const user = getUser();
    if (user.id && !user.token) {
      // saved user config is old, go to login page
      clearUser();
      clearCustomer();
      this.props.history.push(`/${language}/login`);
    } else if (user.id && user.token) {
      // make a test request to check if the token is still active
      getUserDetails();
    }

  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    if (CONFIG.LOG_ERRORS) {
      const userData = getUser();
      const data = {
        text: JSON.stringify(error, Object.getOwnPropertyNames(error)),
        userId: userData.id ? userData.id : null
      }
      axios.post(`${BASE_URL}/front-end-error`, data);
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="site-content">
          <section className="section-fullwidth section-terms-and-conditions">
            <div className="row">
              <div className="column">
                <header className="section-heading">
                  <h2 className="heading-title">Something went wrong.</h2>
                </header>
                <div className="success-buttons text-center">
                  <button onClick={() => {this.props.history.goBack(); window.location.reload()}} className="button button-primary">Обратно към сайта</button>
                </div>
              </div>
            </div>
          </section>
          <RoadSeparator/>
        </div>
      );
    }
    return (
      <>
        {process.env.REACT_APP_STOP &&
          <div className="overlay">
            <div className="maintenance">
              <h1>ПРОФИЛАКТИКА</h1>
              <p>Продажбата на винетки е временно преустановена поради планирана профилактика от страна на Агенция Пътна Инфраструктура</p>
            </div>
          </div>}
        {urlHasLang && this.props.children}
      </>
    )
  }

})