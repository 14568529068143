import React, { Fragment, useState, useRef, useContext } from 'react';
import { useLanguageContext } from '../contexts/languageContext';
import Popup from '../components/Popup';
import PopupQuery from '../components/Form/Popup/PopupQuery';

const PopupContext = React.createContext();
export const usePopupContext = () => useContext(PopupContext);

export function PopupProvider(props) {
  const { gettext } = useLanguageContext();
  const [openState, setOpenState] = useState(false);
  const [options, setOptions] = useState();
  const awaitingPromiseRef = useRef();

  const popupOpen = (options) => {
    setOptions(options);
    setOpenState(true);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const popupClose = data => {
    awaitingPromiseRef.current && awaitingPromiseRef.current.reject(data);
    awaitingPromiseRef.current = null;
    setOpenState(false);
  };

  const popupSubmit = data => {
    awaitingPromiseRef.current && awaitingPromiseRef.current.resolve(data);
    awaitingPromiseRef.current = null;
    setOpenState(false);
  };

  const popupConfirmOpen = (title, text, data, content) => {
    const popupOptions = {
      title: title,
      content: content || ( 
        <div className="panel-popup">
          <div style={{marginBottom: '40px'}}>{text}</div>
        </div>
      ),
      buttons: (
        <Fragment>
          <button className="button button-primary button-ghost" onClick={popupClose}>{gettext('Не')}</button>
          <button className="button button-primary" onClick={() => popupSubmit(data)}>{gettext('Да')}</button>
        </Fragment>
      )
    }
    return popupOpen(popupOptions);
  }

  const popupQueryOpen = (title, text, data, validator) => {
    const popupOptions = {
      title: title,
      content: (<PopupQuery text={text} onSubmit={popupSubmit} onClose={popupClose} validator={validator} />)
    }
    return popupOpen(popupOptions);
  }

  const popupMessageOpen = (title, text) => {
    const popupOptions = {
      title: (<div className="panel-popup">
        <div style={{margin: '40px'}}>{title}</div>
      </div>),
      content: (
          <div className="panel-popup">
            <div style={{marginBottom: '40px'}}>{text}</div>
          </div>
      ),
      buttons: (<button className="button button-primary" onClick={popupSubmit}>{gettext('Ok')}</button>)
    }
    return popupOpen(popupOptions);
  }

  return (
    <PopupContext.Provider value={{ popupOpen, popupConfirmOpen, popupMessageOpen, popupQueryOpen, popupSubmit, popupClose }}>
      <Fragment>
        {props.children}
        <Popup open={openState}
               onClose={popupClose}
               {...options}
        >
          {openState && options && options.content}
        </Popup>
      </Fragment>
    </PopupContext.Provider>
  );
}