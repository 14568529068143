import React, { useState, useContext } from 'react';
import { getCustomer, setCustomer } from '../util/customer';

const CustomerContext = React.createContext();

export const useCustomerContext = () => useContext(CustomerContext);

export function CustomerProvider({ children }) {
    const [customer, setCustomerToState] = useState(getCustomer());

    return (
        <CustomerContext.Provider value={{
            customer,
            setCustomer: c => {
                setCustomer(c);
                setCustomerToState(c);
            },
        }}>
            {children}
        </CustomerContext.Provider>
    );
}
