import React, { useState, useContext, useEffect } from 'react';
import { getVignettePrices } from '../services/vignetteService';
import { useLanguageContext } from './languageContext';
import { useUserContext } from './userContext';
import moment from 'moment';

const VignetteContext = React.createContext();

export const useVignetteContext = () => useContext(VignetteContext);

export function VignetteProvider(props) {
    const KEY_ROUTE_PASS = process.env.REACT_APP_TYPE === 'ERP' ? 'saved_route_passes_erp' : 'saved_route_passes';
    const KEY_VIGNETTE = process.env.REACT_APP_TYPE === 'ERP' ? 'saved-e-vignettes_erp' : 'saved-e-vignettes';

    const [categories, setCategories] = useState([]);
    const [vignetteInfo, setVignetteInfo] = useState(localStorage && localStorage.getItem(KEY_VIGNETTE) ? JSON.parse(localStorage.getItem(KEY_VIGNETTE)) : []);
    const [routePassInfo, setRoutePassInfo] = useState(localStorage && localStorage.getItem(KEY_ROUTE_PASS) ? JSON.parse(localStorage.getItem(KEY_ROUTE_PASS)) : []);
    const [tempVignetteInfo, setTempVignetteInfo] = useState({});
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [confirmData, setConfirmData] = useState();
    const { language } = useLanguageContext();
    const { user } = useUserContext();
    
    useEffect(() => {
        if ((user.token && user.id) || process.env.REACT_APP_TYPE !== 'ERP') { 
            getVignettePrices().then(setCategories);
        }
    }, [language, user.id]);

    const persistData = (data, key) => {
        localStorage && localStorage.setItem(key, JSON.stringify(data));
    };

    const clearVignettes = () => {
        setVignetteInfo([]);
        persistData([], KEY_VIGNETTE);  
    };

    const addNewVignette = () => {
        setTempVignetteInfo({});
    };

    const deleteVignette = (index) => {
        const arr = vignetteInfo.slice(0, index).concat(vignetteInfo.slice(index + 1));
        setVignetteInfo(arr);
        persistData(arr, KEY_VIGNETTE);   
    };

    const buildVignetteInfo = (key, value) => {
        setTempVignetteInfo(Object.assign(tempVignetteInfo, { [key]: value }));
    };

    const saveTempVignetteInfo = () => {
        const arr = [...vignetteInfo, tempVignetteInfo];
        setVignetteInfo(arr);
        persistData(arr, KEY_VIGNETTE);  
        addNewVignette();
    }

    const setVignetteEmail = (email) => {
        let data = [...vignetteInfo];
        data.forEach(obj => obj.email = email);
        setVignetteInfo(data);
    };

    const setActivatedVignettesListForVignette = (vignette, activatedVignettesList) => {
        let data = [...vignetteInfo];
        data.forEach(obj =>  {
            if (vignette === obj) obj.activatedVignettesList = activatedVignettesList
        });
        setVignetteInfo(data);
    };

    const importVignettes = (data) => {
        clearVignettes();
        data.saleRows.forEach((row) => {
            setVignetteInfo([...vignetteInfo, {
                activationDate: moment(row.activationDate).toDate(),
                price: row.price.amount + ' ' + row.price.currency,
                lpn: row.kapschProperties.vehicle.lpn,
                kapschProductId: row.kapschProductId,
                countryCode: row.kapschProperties.vehicle.countryCode,
                periodName: row.validityType,
                emission: row.kapschProperties.vehicle.emissionClass
            }]);
        });
    };

    const addRoutePass = (data) => {
        const arr = [...routePassInfo, data];
        setRoutePassInfo(arr);
        persistData(arr, KEY_ROUTE_PASS);
    };

    const deleteRoutePass = (index) => {
        const arr = routePassInfo.slice(0, index).concat(routePassInfo.slice(index + 1));
        setRoutePassInfo(arr);
        persistData(arr, KEY_ROUTE_PASS);     
    };

    const clearRoutePass = () => {
        setRoutePassInfo([]);
        persistData([], KEY_ROUTE_PASS);     
    };

    return (
        <VignetteContext.Provider value={{
            categories,
            vignetteInfo,
            tempVignetteInfo,
            saveTempVignetteInfo,
            setVignetteInfo: buildVignetteInfo,
            setVignetteEmail,
            setActivatedVignettesListForVignette,
            addNewVignette,
            deleteVignette,
            clearVignettes,
            currentStepIndex,
            setCurrentStepIndex,
            importVignettes,
            routePassInfo,
            addRoutePass,
            deleteRoutePass,
            clearRoutePass,
            confirmData,
            setConfirmData,
        }}>
            {props.children}
        </VignetteContext.Provider>
    );
}
