import React from 'react';
import ServerSideSelect from 'react-select/async';

export default function AsyncSelect({loadOptions, input, ...others}) {
  return ( 
    <ServerSideSelect
      cacheOptions
      className={`multi-select-input ${others.isSearchable ? 'searchable' : ''}`}
      classNamePrefix="react-select"
      loadOptions={loadOptions}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.id}
      defaultOptions
      maxMenuHeight={200}
      {...others}
      {...input}
      //onInputChange={this.handleInputChange}
      onChange={(...args) => {
        input && input.onChange(...args);
        others.onChange && others.onChange(...args);
      }}
    />
  )
};