import React, { useState, useContext, useEffect } from 'react';
import { getAllCountries } from '../services/countriesService';
import { useLanguageContext } from './languageContext';
import { useUserContext } from './userContext';

const CountriesContext = React.createContext();
export const useCountriesContext = () => useContext(CountriesContext);

export function CountriesProvider(props) {
    const [countries, setCountries] = useState([]);
    const { language } = useLanguageContext();
    const { user } = useUserContext();

    useEffect(() => {
        if ((user.token && user.id) || process.env.REACT_APP_TYPE !== 'ERP') { 
            getAllCountries().then(setCountries);
        }
    }, [language, user.id]);

    return (
        <CountriesContext.Provider value={{ countries }}>
            {props.children}
        </CountriesContext.Provider>
    );
}