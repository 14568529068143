import React, {Fragment, useState, useEffect} from 'react';
import { useLanguageContext } from '../../contexts/languageContext';

export default function TabPanel({tabs, children, renderAllTabs=true, elementId, defaultTab = 0, hasScroll = false, autoSwitchToFirstTab = false, setCurrentTabIndex=null}){
  const { gettext } = useLanguageContext();
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    setCurrentTab(defaultTab);
  }, [elementId]);

  useEffect(() => {
      if (autoSwitchToFirstTab) {
          setCurrentTab(defaultTab);
      }
  }, [tabs.length]);

  return (
    <div className={`toggle-tabs toggle-tabs-clean ${hasScroll ? 'toggle-tabs-content-scroll' : ''}`}>
      <ul className="tabs-header">
        {
          tabs.map((tab, index) => 
            <li 
              key={index} 
              className={tab.header ? `tabs-header-item ${currentTab === index ? 'is-current' : ''}` : `hidden-tab`}
              onClick={() => {
                  setCurrentTab(index)
                  setCurrentTabIndex && setCurrentTabIndex(index);
              }}
              style={{color: tab.error ? 'red' : '#000'}}
              title={tab.error ? gettext(tab.error === true ? 'Съдържа невалидни данни' : tab.error) : null }
            >
              {tab.header}
            </li>
          )
        }
        {
          children &&
          <Fragment>
            <li className="tabs-header-item is-wide" />
            <li className="tabs-header-item tabs-header-children">
              {children}
            </li>
          </Fragment>
        }
      </ul>
      {
        renderAllTabs ?
        tabs.map((tab, index) => 
          <div key={index} className={`tab-content tab-content-default ${currentTab === index ? 'is-current' : ''}`}>
            {tab.content}
          </div>
        ) : 
        <div className="tab-content">{tabs[currentTab] ? tabs[currentTab].content : setCurrentTab(0)}</div>
      }
    </div>
  );
}