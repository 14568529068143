import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useLanguageContext } from '../../../contexts/languageContext';
import { useVignetteContext } from '../../../contexts/vignetteContext';

export default function CartPreview(props) {
  const [hover, setHover] = useState(false);
  const [cartCarsOpen, setCartCarsOpen] = useState(undefined);
  const [cartTrucksOpen, setCartTrucksOpen] = useState(undefined);
  const { vignetteInfo, routePassInfo, currentStepIndex, setCurrentStepIndex, clearVignettes, deleteVignette } = useVignetteContext();
  const { gettext, formatCurrency } = useLanguageContext();
  const history = useHistory();
  const allowHover = hover && !!vignetteInfo.length;
  const total = vignetteInfo.reduce((acc, curr) => acc + (curr.price ? curr.price.split(' ').shift()*1 : 0), 0);

  useEffect(() => {
    if (!!vignetteInfo.length && cartCarsOpen === undefined) {
      setCartCarsOpen(true);
    }
    if (!!routePassInfo.length && cartTrucksOpen === undefined) {
      setCartTrucksOpen(true);
    }
  }, [vignetteInfo, routePassInfo]);

  const toggleCartSection = () => setCartCarsOpen(!cartCarsOpen);
  const toggleTruckSection = () => setCartTrucksOpen(!cartTrucksOpen);

  const goToCart = () => {
    if (vignetteInfo.length) {
      setCurrentStepIndex(3);
      history.push('/buy-vignette');
      setHover(false);
    }
  };

  const clearCart = () => {
    clearVignettes();
    setHover(false);
    if (currentStepIndex === 3) {
      setCurrentStepIndex(0);
    }
  }

  const renderVignette = (vignetteInfo) => {
    return vignetteInfo.map(({ lpn, description, price, type, activationDate }, index) => {
      const date = moment(activationDate);
      return (
        <li className="list-item" key={lpn}>
          <div className="item-products">
            <strong className="item-product">{lpn}</strong>
            <span className="item-description">{description}</span>
            <span className="item-validity">{`${gettext('APP.COMMON.COMMON.VALID_FROM')} ${date.format('DD.MM.YYYY')}`}</span>
          </div>
          <div className="item-cost">
            <strong className="item-price">{price}</strong>
            <span className="item-timespan">{type}</span>
          </div>
          <div className="item-delete">
            <div
              className="icon icon-plus rotate45"
              title={gettext('APP.WEB.COMMON.REMOVE_FROM_CART')}
              onClick={() => {
                if (vignetteInfo.length === 1) {
                  setCurrentStepIndex(0);
                }
                deleteVignette(index);
              }}
            ></div>
          </div>
        </li>
      );
    });
  };

  const renderContent = () => {
    return (
      <div className="popup-cart">
        <header className="box-summary-header">
          <div className="icon icon-large icon-cart"></div>
          <h3 className="box-summary-title">{gettext('APP.COMMON.COMMON.ORDER_DETAILS')}</h3>
        </header>

        <section>
          <div className="cart-summary-title text-large-underline" onClick={toggleCartSection}>
            <span style={{position: 'relative'}}>
              {gettext('Леки автомобили до 3.5 т')}
              {!!vignetteInfo.length && <span className="shopping-cart-items shopping-cart-section">{vignetteInfo.length}</span>}
            </span>
          </div>
          { cartCarsOpen && <Fragment>
            <ul className="box-summary-purchases list-purchases">
              {renderVignette(vignetteInfo)}
            </ul>
          
          <div className="box-summary-final">
            <p>{gettext('Крайна сума:')} <span className="box-summary-total">{formatCurrency(total)}</span></p>
          </div>

          <div className="cart-buttons">
            <button onClick={goToCart} className="button button-primary">{gettext('Плащане')}</button>
            <button onClick={clearCart} className="button button-primary button-ghost">
              {gettext('Изчистване')}
            </button>
          </div>
          </Fragment>}
        </section>

        <section>
          <div className="cart-summary-title text-large-underline" onClick={toggleTruckSection}>
            <span style={{position: 'relative'}}>
              {gettext('Превозни средства над 3.5 т')}
              {!!routePassInfo.length && <span className="shopping-cart-items shopping-cart-section">{routePassInfo.length}</span>}
            </span>
          </div>

          { cartTrucksOpen && <Fragment>
            <ul className="box-summary-purchases list-purchases">
              {renderVignette(vignetteInfo)}
            </ul>
          
          <div className="box-summary-final">
            <p>{gettext('Крайна сума:')} <span className="box-summary-total">{formatCurrency(total)}</span></p>
          </div>

          <div className="cart-buttons">
            <button onClick={goToCart} className="button button-primary">{gettext('Плащане')}</button>
            <button onClick={clearCart} className="button button-primary button-ghost">
              {gettext('Изчистване')}
            </button>
          </div>
          </Fragment>}
        </section>
      </div>
    )
  }

  return (
    <div className={`menu-shopping-cart ${allowHover ? 'menu-shopping-cart-hover' : ''}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >

      <div className="icon icon-cart icon-white" style={{cursor: 'pointer', zIndex: 100}} onClick={() => history.push('/shopping-cart')}></div>
      {(!!vignetteInfo.length || !!routePassInfo.length) && <span className="shopping-cart-items">{vignetteInfo.length + routePassInfo.length}</span>}

      {/*allowHover && renderContent()*/}
    </div>
  );
}
