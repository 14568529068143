import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Link from '../../components/LinkLng';
import CartPreview from './CartPreview';
import LanguagePicker from './LanguagePicker';
import UserDropdown from './UserDropdown';
import { useLanguageContext } from '../../contexts/languageContext';
import LanguageFlag from './LanguageFlag';
import { LANGUAGES } from '../../util/constants';
import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';

const Header = function({history}) {
  const main = useRef(null);
  const [headroom, setHeadroom] = useState(false);
  const { gettext, setLanguage, getPageLang } = useLanguageContext();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [mobileLangOpen, setMobileLangOpen] = useState(false);

  useEffect(() => {
    window.onscroll = () => setHeadroom(window.pageYOffset > main.current.offsetTop);
  }, []);

  getPageLang();
  const menuItems = [
    {
      title: gettext('Продукти'),
      items: [
        {
          title: gettext('APP.WEB.COMMON.E-VIGNETTES'),
          link: '/buy-vignette'
        },{
          title: gettext('Маршрутни карти'),
          link: '/buy-route-pass'
        },{
          title: gettext('Нарушения'),
          link: '/pay-delict'
        }
      ]
    },
    {
      title: gettext('За пътните такси'),
      items: [
        {
          title: gettext('За електронни винетки'),
          link: '/about-e-vignette'
        },{
          title: gettext('За маршрутни карти'),
          link: '/about-route-pass'
        },{
          title: gettext('За тол такси'),
          link: '/about-toll-system'
        },{
          title: gettext('За нарушения'),
          link: '/about-delicts'
        }
      ]
    },{
      title: gettext('Цени'),
      link: '/prices'
    },{
      title: gettext('Новини'),
      link: '/news'
    },{
      title: gettext('Често задавани въпроси'),
      items: [
        {
          title: gettext('За електронни винетки'),
          link: '/faqVignette'
        },{
          title: gettext('За тол/МК'),
          link: '/faqToll'
        },{
          title: gettext('За нарушения'),
          link: '/faqDelicts'
        }
      ]
    },{
      title: gettext('За нас'),
      items: [
        {
          title: gettext('За DigiToll'),
          link: '/about-digitoll'
        },{
          title: gettext('APP.COMMON.COMMON.CONTACTS'),
          link: '/contacts'
        }
      ]
    }
  ];

  const langItems = LANGUAGES.map(({ lang }) => ({
    props: {
      onClick: () => setLanguage(lang)
    },
    jsx: <LanguageFlag lang={lang} showText/>
  }));

  return (
    <div className={`site-header headroom ${headroom ? 'headroom--not-top' : 'headroom--top'}`}>

      <div className={`site-header-main`} ref={main}>
        <div className="row wide">
          <div className="column small-12">
            <div className="site-header-inner">
              <div className="site-logo"><Link to="/">{gettext('DigiToll')}</Link></div>

              <nav className="site-navigation site-navigation-dropdown" id="site-navigation">
                <div className="menu-icon menu-icon-lang" onClick={() => {
                  setMobileNavOpen(false);
                  setMobileLangOpen(!mobileLangOpen);
                }}>
                  <LanguageFlag />
                </div>
                
                <div className="menu-icon menu-icon-nav icon icon-bars icon-primary" onClick={() => {
                  setMobileLangOpen(false);
                  setMobileNavOpen(!mobileNavOpen);
                }}/>
                <DesktopMenu items={menuItems} />
              </nav>

              <CartPreview />
              <LanguagePicker />
              <UserDropdown />

            </div>
          </div>
        </div>

        <MobileMenu open={mobileLangOpen} setOpen={setMobileLangOpen} items={langItems} />
        <MobileMenu open={mobileNavOpen} setOpen={setMobileNavOpen} items={menuItems} />

      </div>
    </div>
  );
};

export default withRouter(Header);