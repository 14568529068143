import React from 'react';
import { Form } from 'react-final-form';
import { useLanguageContext } from '../../../contexts/languageContext';
import { usePopupContext } from '../../../contexts/popupContext';
import { handleErrorMessage } from '../../../util/request';
import { setItem } from '../../../util/storage';
import { openShift } from '../../Services/posService';
import EditForm from '../../components/EditForm';
import PosField from '../../components/Fields/PosField';

export default function Shift({ onSave }) {
  const { gettext } = useLanguageContext();
  const { popupMessageOpen } = usePopupContext();

  const onSubmit = ({pos}) => {
    openShift(pos)
      .then(({data}) => setItem('cashier-shift', data.id))
      .then(onSave)
      .catch(err => popupMessageOpen(gettext('Грешка'), handleErrorMessage(err)))
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({values, ...formProps }) => {

        const formFields = (
          <div className="field-container">
            <div className="form-inline form-inline-border">
              <PosField showSingleValue filterCondition={'cashierShiftMode'} />
            </div>
          </div>
        );

        const tabs = [
          {header: gettext('APP.COMMON.COMMON.DETAILS'), content: formFields}
        ];

        return (<EditForm formProps={formProps} tabs={tabs} activeSaveButton />);
      }
    } />
  );
}