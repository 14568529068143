import React from 'react';
import MenuItem from './MenuItem';

export default function MobileMenu({open, setOpen, items}) {
  return (
    <div className={`site-header-mobilenav ${open ? 'is-visible' : ''}`}>
      <ul className="menu-mobile">
        { items.map((item, idx) => <MenuItem item={item} key={idx} close={()=>setOpen(false)}/> )}
      </ul>
    </div>
  );
}