import axios from 'axios';
import { BASE_URL } from '../../util/constants';
import {downloader, generateFilterParams} from '../../util/request';
import {getWithCancel} from "../../util/common";

export function getPartners(params) {
  return axios.get(`${BASE_URL}/partners`, params).then(data => data.data.elements);
}

export function partnersTollExport(params) {
  return axios({
    url: `${BASE_URL}/partners/export`,
    method: 'GET',
    responseType: 'blob',
    params: {
      ...params.params
    }
  }).then(downloader('partners.csv'));
}

export function sendSalesPartnerReports(params) {
  let tempParams = {
    params: {
      "partner_id": params.partner_id,
      "from_date": params.from_date
    }
  };
  if (params.email) {
    tempParams.params.email = params.email;
  }
  return axios.get(`${BASE_URL}/send/reports/partners`, tempParams)
}

export function getSalesPartners(params) {
  return axios.get(`${BASE_URL}/partners/salePartnersAndCommissioners`, params);
}

export function getVendors() {
  return axios.get(`${BASE_URL}/partners/getVendors`);
}

export function getOverchargePackages() {
  return axios.get(`${BASE_URL}/msts/overcharges`);
}

export function changePartnerCommissioners(data) {
  return axios.post(`${BASE_URL}/partners/changePartnerCommissioners`, data);
}

export function addSalesPartner(data) {
  return axios.post(`${BASE_URL}/partners/createSalesPartner`, data);
}

export function updateSalesPartner(data) {
  return axios.put(`${BASE_URL}/partners/updateSalesPartner/${data.id}`, data);
}

export function getPartnersPagination(params, additionalParams = {}) {
  const newParams = additionalParams.onlyCustomers ? {params: {
    ...params.params,
    f_partnerType: 'TOLL_CUSTOMER'
  }} : params;
  if (additionalParams.showApis) {
    newParams.params["showApis"] = true;
  }
  return axios.get(`${BASE_URL}/partners`, newParams);
}

export function addPartner(data) {
  const newdata = {...data, partnerType: 'TOLL_CUSTOMER'};
  return axios.post(`${BASE_URL}/partners`, newdata);
}

export function addVignettePartner(data) {
  const newdata = {...data};
  return axios.post(`${BASE_URL}/partners`, newdata);
}

export function updatePartner(data) {
  return axios.put(`${BASE_URL}/partners/${data.id}`, data);
}

export function getPartnerDataFromApis(params) {
  return axios.post(`${BASE_URL}/partners/apis`, params);
}
//For all report types
// export function getReportTypes() {
//   return axios.get(`${BASE_URL}/partners/report-types`).then(data => data.data);
// }

export function getPartnerById(id) {
  return axios.get(`${BASE_URL}/partners/${id}`).then(data => data.data);
}

export function getPartnerByCustomerId(id) {
  const params = {
    params: {
      'f_customerC5.customerID': id,
      page_number: 0,
      page_size: 10
    }
  };
  return id ? axios.get(`${BASE_URL}/partners`, params).then(data => data.data.elements.length ? data.data.elements[0] : null) : Promise.resolve(null);
}

export function getPartnersWithAvailableIbansByCustomerId(id) {
  const params = {
    params: {
      'f_customerC5.customerID': id,
      page_number: 0,
      page_size: 10
    }
  };
  return id ? axios.get(`${BASE_URL}/partners/with-available-ibans`, params).then(data => data.data.elements.length ? data.data.elements[0] : null) : Promise.resolve(null);
}

export function sendCustomersReport(data) {
  return axios.post(`${BASE_URL}/send/reports/bypartnerservice`, data)
}

export function sendMstsCustomerReport(data) {
  return axios.post(`${BASE_URL}/send/reports/msts/customers`, data)
}

export function sendCustomersReportAccoutant(data) {
  const params = {
    params: data
  }
  return axios.get(`${BASE_URL}/send/reports/accountant`, params)
}

export function sendPartnerReports(data) {
  return axios.post(`${BASE_URL}/storage/send/reports/to-partners`, data)
}

export function getAllContracts(params) {
  return getWithCancel(`${BASE_URL}/accounts`, params);
}

export function contractsExport(params, partnerId) {
  return axios({
    url: `${BASE_URL}/file/report/accounts`,
    method: 'GET',
    responseType: 'blob',
    params: {
      ...params.params,
      ...(partnerId && {'f_customerID': partnerId}),
    }
  }).then(downloader('contracts.csv'));
}

export function getContracts(params, partnerId) {
  return partnerId ? axios.get(`${BASE_URL}/accounts/customer/${partnerId}`, params) : Promise.resolve({data: []});
}

export function getSingleContract(accountId) {
  return axios.get(`${BASE_URL}/accounts/${accountId}`);
}

export function addContract(data, partnerId) {
  const newdata = {
    ...data,
    customerID: partnerId,
    fundingMethodCode: 1,
    daysInAdvanceToCreateServiceFee: 10,
    serviceFeeGenerationFrequency: 1,
    ...(data.contacts.length === 1 && {
      contacts: [{...data.contacts[0], primaryAccountContact: true}]
    })
  };
  return axios.post(`${BASE_URL}/accounts`, newdata);
}

export function getCloseContractReasons(params) {
  return axios.get(`${BASE_URL}/v1/c5/master/account-close-reasons`, params);
}

export function preCloseAccount(params) {
  return axios.post(`${BASE_URL}/accounts/pre-close`, params);
}

export function updateContract(data) {
  return axios.put(`${BASE_URL}/accounts/${data.id}`, data);
}

export function updateContractOwner(fromId, toId) {
  const data = {
    partnerId: fromId,
    ownerId: toId
  };
  return axios.post(`${BASE_URL}/partners/changeOwner`, data);
}

export function getVehicles(params) {
  return axios.get(`${BASE_URL}/accountUnits/filter`, params);
}

export function closeVehicle(id) {
  return axios.post(`${BASE_URL}/accountUnits/closeUnit/${id}`);
}

export function getVehiclesByContractId(params, contractId) {
  return contractId ? axios.get(`${BASE_URL}/accountUnits/account/${contractId}`, params): Promise.resolve({data: []});
}

export function addVehicle(params, accountId) {
  const data = {
    accountID: accountId,
    accountUnits: [{
      ...params,
      shippingAddressId: params.shippingAddress.id,
      deliveryMethod: 2,
      tyreType: 1
    }]
  };
  return axios.post(`${BASE_URL}/accountUnits`, data);
}

export function updateVehicle(data) {
  return axios.put(`${BASE_URL}/accountUnits/${data.accountUnitId}`, data);
}

export function verifySingleVehicle(accountId, unitId) {
  return axios.post(`${BASE_URL}/accountUnits/singleUnitVerification?account_id=${accountId}&unit_id=${unitId}`);
}

export function vehiclesExport(params, accountId) {
  return axios({
    url: `${BASE_URL}/accountUnits/export`,
    method: 'GET',
    responseType: 'blob',
    params: {
      ...params.params,
      ...(accountId && {accountId: accountId}),
    }
  }).then(downloader('vehicles.csv'));
}

export function syncUnit(unitId) {
  return axios.post(`${BASE_URL}/accountUnits/syncUnit/${unitId}`);
}

export function syncAllAccountUnits(accountId) {
  return axios.post(`${BASE_URL}/accountUnits/syncUnitsForAccount/${accountId}`);
}

export function getProductTypes(id) {
  return axios.get(`${BASE_URL}/v1/c5/master/producTypes/${id}`).then(data => data.data);
}

export function getKATData(data) {
  return axios.post(`${BASE_URL}/accountUnits/getUnitDataWithRegix`, data);
}

export function getAllNotificationsByAccountId(params, id) {
  return axios.get(`${BASE_URL}/notificationReport/notifications/contractId/${id}`, params);
}

export function getEmailsByAccountId(params, id) {
  return axios.get(`${BASE_URL}/notificationReport/email/contractId/${id}`, params);
}

export function getAllNotification(params) {
  return axios.get(`${BASE_URL}/notificationReport/notifications/filtered`, params);
}

export function getEmails(params) {
  return axios.get(`${BASE_URL}/notificationReport/email/filtered`, params);
}

export function payMassDeposit(accountId) {
  return axios.post(`${BASE_URL}/accountUnits/massDepositPayment`, { accountId: accountId });
}

export function detailsForNotification(id) {
  return axios.get(`${BASE_URL}/notificationReport/notifications/id/${id}`);
}

export function createSelfServiceUser(customerId, username) {
  return axios.post(`${BASE_URL}/self-service/user`, { customerId, username });
}

export function unlinkSelfServiceUser(customerId) {
  return axios.get(`${BASE_URL}/self-service/unlink-partner`, {params: {customerId}});
}

export function getSelfServiceUser(customerId, remoteClientId) {
  return axios.get(`${BASE_URL}/self-service/retrieve-user-details`, {params: {customerId, remoteClientId}});
}

export function getTrackerManagement(partner_id) {
  return axios.get(`${BASE_URL}/user-tdp/?partnerId=${partner_id}`);
}

export function createTracker(values) {
  return axios.post(`${BASE_URL}/user-tdp/create`, values);
}

export function updateTracker(values) {
  return axios.put(`${BASE_URL}/user-tdp/update`, values);
}

export function checkValidRange(start, end) {
  return axios.get(`${BASE_URL}/user-tdp/inRange?validRangeStart=${start}&validRangeEnd=${end}`);
}

export function checkTdpId(tdpId) {
  return axios.get(`${BASE_URL}/partners/isTdpIdDuplicated/${tdpId}`);
}

export function checkIsTrackerAttached(customerId) {
  return axios.get(`${BASE_URL}/partners/isTrackerAttached?customerID=${customerId}`);
}

export function getPaymentTerminalContext(){
  return axios.get(`${BASE_URL}/paymentTerminalContexts`)
}