import React, {useState, useEffect, useContext} from 'react';
import {FEATURES} from "../config/features";

const FeaturesContext = React.createContext();

export const useFeaturesContext = () => useContext(FeaturesContext);

export function FeaturesProvider({children}) {
    const [featuresData, setFeaturesData] = useState({});

    const parseMap = (map) => {
        let features = FEATURES;
        Object.keys(features).map((value, index) => {
            features[value] = !!parseInt(map.charAt(index));
        });
        return features;
    }


    const createMap = (features) => {
        let map = "";
        Object.keys(features).map((feat,) => {
            map += Number(features[feat]);
        })

        return map;
    }

    const handleSetFeatures = (features) => {
        setFeaturesData(JSON.parse(JSON.stringify(features)));
    }

    useEffect(() => {
        if (localStorage.getItem("FEATURES_MAP")) {
            if (localStorage.getItem("FEATURES_MAP").length != Object.keys(FEATURES).length) {
                handleSetFeatures(FEATURES);
                localStorage.removeItem("FEATURES_MAP");
            } else {
                handleSetFeatures(parseMap(localStorage.getItem("FEATURES_MAP")));
            }
        } else {
            handleSetFeatures(FEATURES);
        }
    }, []);

    return (
        <FeaturesContext.Provider value={{
            featuresData,
            setFeaturesHandle: features => {
                handleSetFeatures(features);
                localStorage.setItem("FEATURES_MAP", createMap(features));
            },
            removeFeaturesHandle: () => {
                handleSetFeatures(FEATURES);
                localStorage.removeItem("FEATURES_MAP");
            },
            hasFeature: (feature) => {
                return featuresData[feature];
            },
        }}>
            {children}
        </FeaturesContext.Provider>
    )
}