const meta = {
  bg: {
    '/bg/' : { title: 'Купи Електронна Винетка Онлайн | DigiToll', description: 'Купете своята електронна винетка бързо, лесно и сигурно от Digitoll.bg. Eелектронни винетки за всички категории превозни средства в 5 лесни стъпки изцяло онлайн.' },
    '/bg/about-digitoll/' : { title: 'Научи Повече за DigiToll | DigiToll', description: 'Digitoll.bg e национален доставчик на услуги, съгласно сключен договор с АПИ. Тук може да закупите легитимна електронна винетка онлайн бързо и лесно с няколко клика.' },
    '/bg/about-e-vignette/' : { title: 'Как се купува Е-Винетка Онлайн | DigiToll', description: 'Електронните винетки са напълно легитимни документи, даващи право на ползване на платената пътна мрежа. Купете е-винетка в Digitoll.bg с 5 лесни стъпки онлайн без регистрация.' },
    '/bg/buy-vignette/' : { title: 'Всички Категории Електронни Винетки | DigiToll', description: 'Твоята електронна винетка те очаква на един клик разстояние. Купи е-винетка от официален доставчик на услуги Digitoll.bg в 5 прости стъпки с банкова карта.' },
    '/bg/contacts/' : { title: 'Контакти | DigiToll', description: 'Имаш нужда от съдействие за покупка на електронна винетка онлайн и търсиш контакт с нас? Digitoll.bg те очаква на телефон, имейл или на адрес в София.' },
    '/bg/cookie-policy/' : { title: 'Cookie Policy | DigiToll', description: 'Политика за използване на Бисквитки', noindex: true },
    '/bg/faqVignette/' : { title: 'Отговори на всички въпроси за е-Винетки | Digitoll', description: 'За кои ППС има изискване за винетна такса? От хартиени към е-Винетки? Цени и други често задавани въпроси в Digitoll.bg. Покупка на е-Винетки лесно и сигурно онлайн.' },
    '/bg/login/' : { title: 'Login | DigiToll', description: '', noindex: true },
    '/bg/user/forgotten-password/' : { title: 'Забравена парола | DigiToll', description: '', noindex: true },
    '/bg/terms-and-conditions/' : { title: 'Общи условия | DigiToll', description: '', noindex: true },
    '/bg/prices/' : { title: 'Цени от Национален Доставчик | DigiToll', description: 'Digitoll.bg e национален доставчик на услуги, съгласно сключен договор с АПИ. Виж всички Цени на отделните категории винетки. Закупи Онлайн Бързо и Лесно!' },
    '/bg/privacy-policy/' : { title: 'Privacy Policy | DigiToll', description: 'Правила за обработка, защита и съхранение на личните данни на физически лица. ', noindex: true },
    '/bg/register/' : { title: 'Регистрация | DigitToll', description: '' },
    '/bg/annual-e-vignette/' : { title: 'Годишна Е-Винетка - Купи бързо и Лесно | Digitoll', description: 'Вземи Годишна Винтека за всички категории МПС от DigiToll - Национален Доставчик на Винетки  - Категория 1 - Категория 2 - Категория 3' },
    '/bg/check-for-validity-of-e-vignette/' : { title: 'Безплатна Проверка на E-Винетка | Digitoll', description: 'Проверете Валидността на Вашата E-Винетка Бързо и Лесно за секунди. Пътувайте спокойно по републиканската пътна мрежа!' },
    '/bg/weekly-e-vignette/' : { title: 'Седмична Винетка - Онлайн Проверка и Закупуване | Digitoll', description: 'Вземи Седмична Винтека за всички категории МПС от DigiToll - Национален Доставчик на Винетки  - Категория 1 - Категория 2 - Категория 3' },
    '/bg/how-to-buy-e-vignette-from-digitoll/' : { title: 'От къде и как да си купим винетка онлайн | DigiToll', description: 'Вижте как лесно в 5 бързи стъпки може да закупите Електронна винетка от DigiToll. Пътувайте спокойно по републиканската пътна мрежа!' },
    '/bg/buy-route-pass/' : { title: 'Маршрутни карти за превозни средства над 3.5 т | DigitToll', description: 'Закупете Онлайн Маршрутни карти за превозни средства над 3.5 т от DigiToll.bg - Национален Доставчик за България.' }
  },
  en: {
    '/en/' : { title: 'Buy E-Vignette in Bulgaria Online | DigiToll', description: 'Buy your E-Vignette quickly, easily and securely from Digitoll.bg. Electronic vignettes in Bulgaria for all vehicles categories in 5 easy steps all online.' },
    '/en/about-digitoll/' : { title: 'Learn more about DigiToll | DigiToll', description: 'Digitoll.bg is a national service provider under contract with RIA. Here you can buy legitimate e-vignette online quickly and easily with a few clicks.' },
    '/en/about-e-vignette/' : { title: 'How To Buy E-Vignette Online in Bulgaria | DigiToll', description: 'Buy your E-Vignette quickly, easily and securely from DigiToll.bg. Electronic vignettes in Bulgaria for all vehicles categories in 5 easy steps all online.' },
    '/en/buy-vignette/' : { title: 'Buy E-Vignette for All Vehicle Categories in Bulgaria | DigiToll', description: 'Your Е-vignette is just a click away. Buy an E-vignette in Bulgaria in 5 simple steps with a bank card from DigiToll.bg - Official Service Provider.' },
    '/en/contacts/' : { title: 'Contact Us | DigiToll', description: 'Need assistance for purchasing an E-vignette online in Bulgaria? Have an enquiry? We are available by phone, email or at our business address in Sofia.' },
    '/en/cookie-policy/' : { title: 'Cookie Policy | DigiToll', description: 'Policy on the use of Cookies', noindex: true },
    '/en/faqVignette/' : { title: 'FAQ E-Vignettes | DigiToll', description: 'Which vehicles are required to have an e-vignette in Bulgaria? See Prices and Detailed Information about E-vignettes. Buy E-vignettes easily and securely online.' },
    '/en/login/' : { title: 'Login | DigiToll', description: '', noindex: true },
    '/en/user/forgotten-password/' : { title: 'Forgotten password | DigiToll', description: '', noindex: true },
    '/en/terms-and-conditions/' : { title: 'Terms and Conditions | DigiToll', description: '', noindex: true },
    '/en/prices/' : { title: 'Prices by Bulagrian National Provider | DigiToll', description: 'See E-Vignette Prices for all vehicle categories. DigiToll.bg is a national service provider under contract with RIA in Bulgaria. Buy Online Quickly and Easily!' },
    '/en/privacy-policy/' : { title: 'Privacy Policy | DigiToll', description: 'Processing rules protecting the personal data of individuals.', noindex: true },
    '/en/register/' : { title: 'Registration | DigitToll', description: '' },
    '/en/annual-e-vignette/' : { title: 'Buy an Annual E-Vignette in Bulgaria Securely with 2 Clicks | DigiToll', description: 'Get an Annual E-Vignette for all vehicle categories in Bulgaria from DigiToll.bg - National Vignette Provider - Category 1 - Category 2 - Category 3' },
    '/en/check-for-validity-of-e-vignette/' : { title: 'Check Your E-Vignette Validity in Bulgaria Easy with 1 Click | DigiToll', description: 'Check the validity of your E-Vignette Easily in Bulgaria in seconds. Have a trouble free journey on the national road network!' },
    '/en/weekly-e-vignette/' : { title: 'Buy a Weekly E-Vignette in Bulgaria Securely with 2 Clicks | DigiToll', description: 'Get a Weekly E-Vignette for all vehicle categories in Bulgaria from DigiToll.bg - National Vignette Provider - Category 1 - Category 2 - Category 3' },
    '/en/how-to-buy-e-vignette-from-digitoll/' : { title: 'How and where to buy an e-Vignette online | DigiToll', description: '' },
    '/en/buy-route-pass/' : { title: 'Route passes for vehicles in Bulgaria over 3.5t | DigitToll', description: 'Buy Online a route passes for vehicles in Bulgaria over 3.5t. DigiToll.bg - Prices by Bulgarian National Provider' }
  }
}

const bgFaq = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Що е то ЕЛЕКТРОННА ВИНЕТКА?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Електронната винетка е документ по смисъла на Закона за електронния документ и електронните удостоверителни услуги /чл.3, ал.1 от ЗЕДЕУУ/. Чрез него се удостоверява заплащането на винетна такса за ползване на платената пътна мрежа в Република България за определен период от време."
    }
  },{
    "@type": "Question",
    "name": "За кои пътни превозни средства се закупува електронна винетка?Електронна винетка закупуват всички категории пътни превозни средства (ППС), използващи платената пътна мрежа:",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Електронна винетка закупуват всички категории пътни превозни средства (ППС), използващи платената пътна мрежа. Категория 1, Категория 2, Категория 3."
    }
  },{
    "@type": "Question",
    "name": "Какъв документ за плащане ще получа при покупка на електронна винетка през сайта digitoll.bg?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "При покупка на електронна винетка онлайн чрез сайта digitoll.bg ще получите електронна разписка на посочения от Вас имейл адрес при регистрацията си в сайта или на посочения от Вас имейл адрес ако сте нерегистриран потребител."
    }
  },{
    "@type": "Question",
    "name": "Какви са цените и видовете електронни винетки и какви са периодите им на валидност?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Уикенд Винетка - 10 BGN; Седмична Винетка - 15 BGN, Месечна Винетка - 30 BGN, Тримесечна Винетка - 54 BGN, Годишна Винетка - 97 BGN."
    }
  },{
    "@type": "Question",
    "name": "При прехвърляне на собствеността на превозното средство, нужно ли е да се закупи нова електронна винетка?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Електронната винетка се издава за автомобил с определен регистрационен номер. При промяна на регистрационния номер или при покупко-продажба на превозното средство, собственикът или ползвателят следва в срок до 72 часа /три работни дни/ от промяната на собствеността да заяви тази промяна в централното управление или в областните управления на Агенция „Пътна инфраструктура“. Промяната трябва да се отрази в електронната система за издадените винетки. В противен случай превозното средство няма да има активна електронна винетка и Вие може да бъдете санкционирани от контролните органи."
    }
  },{
    "@type": "Question",
    "name": "Има ли възможност да се закупят повече от една винетка с едно плащане по банков път?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "В нашия сайт може да се закупи повече от една винетка с едно плащане. В сайта е невъзможно да се извърши дублиране, тоест закупуване втори път на винетка за един и същ период за едно и също пътно превозно средство."
    }
  },{
    "@type": "Question",
    "name": "Възможно ли е електронната винетка да се валидира от днес?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Да! Електронната винетка е валидна от момента на заплащане и получаване на разписка по имейл като потвърждение за успешно закупуване на електронна винетка."
    }
  },{
    "@type": "Question",
    "name": "Може ли електронната винетка да се закупи по-рано?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Да! Можете да закупите електронна винетка предварително, но не по-рано от 30 дни преди желаната дата на валидиране."
    }
  },{
    "@type": "Question",
    "name": "Възможно ли е да се откаже покупка на електронна винетка след закупуването?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Не! Това не е възможно. Електронната винетка се активира веднага след заплащането и отказът от покупката е невъзможен."
    }
  },{
    "@type": "Question",
    "name": "Може ли да си възвърна заплатената сума чрез digitoll.bg при грешка в нанасянето на данните?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Това не е възможно! При неправилно въведени данни и активирана електронна винетка, заплатените суми не подлежат на възстановяване. Вие носите отговорност за верността на подадените данни при закупуване на електронна винетка през сайта digitoll.bg"
    }
  },{
    "@type": "Question",
    "name": "Как да проверя валидността на електронната си винетка?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Дали Вашата електонна винетка е активирана, можете да проверите на сайта digitoll.bg ако сте регистриран потребител."
    }
  },{
    "@type": "Question",
    "name": "Как мога да получа ново копие от моята разписка за покупка на електронната си винетка?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Моля, влезте в сайта digitoll.bg чрез Вашия потребителски профил. Там ще можете да получите копие от вашата разписка. Ако нямате регистрация, можете да използвате нашата форма за контакт на имейл support@digitoll.bg Можете да ни запитате чрез посочване на регистрационния номер на автомобила."
    }
  },{
    "@type": "Question",
    "name": "Контролните органи ще изискват ли от мен докуминт, че съм закупил електронна винетка?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Документът, че имате закупена електронна винетка е електронната разписка, която сте получили по имейл при покупка на винетката, но не е необходимо да я показвате на контролните органи. Наличието на валидна електронна винетка се контролира чрез стационарни и мобилни камери, които засичат регистрационните номера."
    }
  },{
    "@type": "Question",
    "name": "Необходимо ли е да конвертирам в електронна винетка стикер, който съм закупил преди 01.01.2019 със срок на валидност, изтичащ след 1 януари 2019г.?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Не. След изтичане на срока на валидност на стикера е необходимо да закупите електронна винетка. При проверка е нужно представите валиден стикер - перфориран и залепен на предното стъкло на превозното средство."
    }
  },{
    "@type": "Question",
    "name": "Трябва да закупя електронна винетка за камион с общо тегло до 12 тона, към който ще прикача ремарке. Какъв тип електронна винетка ми е необходима?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Ако композицията камион + ремарке е повече от 12 тона общо тегло и/или е с повече от 2 оси, е необходимо да закупите електронна винетка за Категория 1."
    }
  },{
    "@type": "Question",
    "name": "Нужно ли е да се закупи електронна винетка за тежкотоварен автомобил или автобус?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Да. За момента за този тип превозни средства винетната такса се прилага. Закупува се електронна винетка съответно за Категория 1 и 2. В бъдеще пътните превозни средства Категория 1 и 2 ще заплащат тол такса на база изминато разстояние."
    }
  },{
    "@type": "Question",
    "name": "За мотоциклет или ATV закупува ли се електронна винетка?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Не. Мотоциклети, триколки и ATV не заплащат винетна такса."
    }
  },{
    "@type": "Question",
    "name": "Закупувайки електронни винетки през сайта digitoll.bg за превозни средства, като юридическо лице, ще получа ли фактура с ДДС?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Тъй като винетната такса е държавна такса, върху нея не се начислява ДДС. При покупка на електронни винетки онлайн чрез сайта digitoll.bg ще получите електронна разписка, която представлява документ по смисъла на чл.3 ал.1 от ЗЕДЕУУ /Закон за електронния документ и електронните удостоверителни услуги/, на посочения от Вас имейл адрес при регистрацията си в сайта или на посочения от Вас имейл адрес ако сте нерегистриран потребител."
    }
  },{
    "@type": "Question",
    "name": "Безплатна винетка? Как да я получа?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "По ред, определен с наредба на министъра на транспорта, информационните технологии и съобщенията и министъра на труда и социалната политика, безплатна едногодишна винетка се полага на лица с 50 и над 50 на сто намалена работоспособност или вид и степен на увреждане за един лек автомобил, негова собственост или съпружеска имуществена общност, с обем на двигателя до 2000 куб. см и мощност до 117,64 kW (160 к.с.). Също така от заплащане на винетна такса се освобождават лицата или семействата, отглеждащи деца с трайни увреждания до 18-годишна възраст и до завършване на средното образование, но не по-късно от 20-годишна възраст."
    }
  }]
};

const bgHome = {
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "DigiToll",
  "alternateName": "Електронни Винетки - Digitoll",
  "url": "https://www.digitoll.bg/bg/",
  "logo": "https://www.digitoll.bg/logo.svg"
};

const bgAnnual = {
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Годишна Електронна Винетка",
  "image": "https://www.digitoll.bg/static/media/category3_light_vehicle.0da3dda2.svg",
  "description": "Годишна Е-Винетка от Digitoll. Избери категория на вашето превозно средство и купи сега онлайн от национален доставчик.",
  "brand": "Digitoll",
  "sku": "2022",
  "gtin8": "2022",
  "offers": {
    "@type": "AggregateOffer",
    "url": "https://www.digitoll.bg/bg/annual-e-vignette",
    "priceCurrency": "BGN",
    "lowPrice": "10",
    "highPrice": "97",
    "offerCount": "150000"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "6.0",
    "bestRating": "6.0",
    "worstRating": "1",
    "ratingCount": "415",
    "reviewCount": "25052"
  },
  "review": {
    "@type": "Review",
    "name": "Боян",
    "reviewBody": "Много добре направен сайт! Лесно за навигиране и плащане!",
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": "6.0",
      "bestRating": "6.0",
      "worstRating": "1"
    },
    "datePublished": "2019-12-10",
    "author": {"@type": "Person", "name": "Боян Петков"},
    "publisher": {"@type": "Organization", "name": "Digitoll"}
  }
};

const bgQuarterly = {
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Тримесечна Електронна Винетка",
  "image": "https://www.digitoll.bg/static/media/category3_light_vehicle.0da3dda2.svg",
  "description": "Тримесечна Е-Винетка от Digitoll. Избери категория на вашето превозно средство и купи сега онлайн от национален доставчик.",
  "brand": "Digitoll",
  "sku": "2022",
  "gtin8": "2022",
  "offers": {
    "@type": "AggregateOffer",
    "url": "https://www.digitoll.bg/bg/quarterly-e-vignette",
    "priceCurrency": "BGN",
    "lowPrice": "10",
    "highPrice": "97",
    "offerCount": "150000"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "6.0",
    "bestRating": "6.0",
    "worstRating": "1",
    "ratingCount": "415",
    "reviewCount": "25052"
  },
  "review": {
    "@type": "Review",
    "name": "Иван",
    "reviewBody": "Много удобен сайт при покупка на винетка.",
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": "6.0",
      "bestRating": "6.0",
      "worstRating": "1"
    },
    "datePublished": "2021-01-10",
    "author": {"@type": "Person", "name": "Иван Стоянов"},
    "publisher": {"@type": "Organization", "name": "Digitoll"}
  }
};

const bgWeekly = {
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Седмична Електронна Винетка",
  "image": "https://www.digitoll.bg/static/media/category3_light_vehicle.0da3dda2.svg",
  "description": "Седмична Е-Винетка от Digitoll. Избери категория на вашето превозно средство и купи сега онлайн от национален доставчик.",
  "brand": "Digitoll",
  "sku": "2022",
  "gtin8": "2022",
  "offers": {
    "@type": "AggregateOffer",
    "url": "https://www.digitoll.bg/bg/quarterly-e-vignette",
    "priceCurrency": "BGN",
    "lowPrice": "10",
    "highPrice": "97",
    "offerCount": "150000"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "6.0",
    "bestRating": "6.0",
    "worstRating": "1",
    "ratingCount": "415",
    "reviewCount": "25052"
  },
  "review": {
    "@type": "Review",
    "name": "Ангел",
    "reviewBody": "Страхотен сайт!",
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": "6.0",
      "bestRating": "6.0",
      "worstRating": "1"
    },
    "datePublished": "2021-02-17",
    "author": {"@type": "Person", "name": "Ангел Василев"},
    "publisher": {"@type": "Organization", "name": "Digitoll"}
  }
};

const bgWeekend = {
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Уикенд Електронна Винетка",
  "image": "https://www.digitoll.bg/static/media/category3_light_vehicle.0da3dda2.svg",
  "description": "Уикенд Е-Винетка от Digitoll. Избери категория на вашето превозно средство и купи сега онлайн от национален доставчик.",
  "brand": "Digitoll",
  "sku": "2022",
  "gtin8": "2022",
  "offers": {
    "@type": "AggregateOffer",
    "url": "https://www.digitoll.bg/bg/quarterly-e-vignette",
    "priceCurrency": "BGN",
    "lowPrice": "10",
    "highPrice": "97",
    "offerCount": "150000"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "6.0",
    "bestRating": "6.0",
    "worstRating": "1",
    "ratingCount": "415",
    "reviewCount": "25052"
  },
  "review": {
    "@type": "Review",
    "name": "Гергана",
    "reviewBody": "Много добър сайт!",
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": "6.0",
      "bestRating": "6.0",
      "worstRating": "1"
    },
    "datePublished": "2021-03-01",
    "author": {"@type": "Person", "name": "Гергана Димитрова"},
    "publisher": {"@type": "Organization", "name": "Digitoll"}
  }
};

const structuredData = {
  bg: {
    '/bg/': bgHome,
    '/bg/faqVignette/': bgFaq,
    '/bg/annual-e-vignette/': bgAnnual,
    '/bg/quarterly-e-vignette/': bgQuarterly,
    '/bg/weekly-e-vignette/': bgWeekly,
    '/bg/weekend-e-vignette/': bgWeekend
  }
};

const setMeta = (query, data, attr='content', setAttr=true) => {
  const tag = document.querySelector(query);
  tag && (setAttr ? tag.setAttribute(attr, data) : tag[attr] = data)
};

export function updateMeta(route, lang) {
  setMeta('meta[property="og:url"]', window.location.href);
  if (meta[lang] && meta[lang][route]) {
    document.title = meta[lang][route].title;
    document.documentElement.setAttribute('lang', lang);
    setMeta('meta[property="og:description"]', meta[lang][route].description);
    setMeta('meta[name="description"]', meta[lang][route].description);
    setMeta('meta[name="robots"]', meta[lang][route].noindex ? 'noindex, follow' : '');
    setMeta('link[rel="canonical"]', window.location.href, 'href');
    setMeta('script[id="structuredData"]', structuredData[lang] && structuredData[lang][route] ? JSON.stringify(structuredData[lang][route]) : '', 'text', false);
  } else {
    document.title = 'DigiToll';
  }

}