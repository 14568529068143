import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLanguageContext } from '../../../../contexts/languageContext';
import { useErpContext } from '../../../../contexts/erpContext';

export default function MenuItem({item, close, subitem}) {
  const { language } = useLanguageContext();
  const { userAllowed } = useErpContext();
  const [open, setOpen] = useState(false);

  const showItem = () =>  item.access != null ? (
    (typeof item.access === 'string' || item.access instanceof String) ? userAllowed(item.access) : item.access
  ) : true;

  const menuClick = () => {
    if (item.items) {
      setOpen(!open);
    } else {
      close();
    }
    if (item.props && item.props.onClick) {
      item.props.onClick();
    }
  }

  return (
    showItem() &&
    <Fragment>
      <li className={`menu-item ${subitem ? 'menu-item-submenu' : ''}`} {...item.props} onClick={menuClick}>
        {
          item.link ?
          <Link to={`/${language}${item.link}`}>{item.title}</Link> :
          (item.jsx ? item.jsx : <div>{item.title}</div>)
        }
      </li>
      { item.items && open && item.items.map((item, idx) => <MenuItem item={item} key={idx} close={close} subitem /> )}
    </Fragment>
  );
}