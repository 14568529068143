import React from 'react';
import FlagBg2x from '../../../images/flag-bg@2x.png';
import FlagEn2x from '../../../images/flag-en@2x.png';
import { LANGUAGES } from '../../../util/constants';
import { useLanguageContext } from '../../../contexts/languageContext';

const ICONS = {
  'flag-bg': FlagBg2x,
  'flag-en': FlagEn2x
};

export default function LanguageFlag({lang, showText}) {
  const { language } = useLanguageContext();

  if (!lang) {
    lang = language;
  }

  const { icon, text } = LANGUAGES.find(v => v.lang === lang);

  return (
    <>
      <img className="language-select" alt={text} src={ICONS[icon]}/>
      <div className="language-text">{ showText && text}</div>
    </>
  );
}