import React from 'react';
import Image from '../../images/road-all2.svg';
import ImageSmall from '../../images/vehicles-all-2.svg';

export default function RoadSeparator({small}) {
  return ( 
      <section className="section-road-separator">
        <img className="vehicle vehicle-truck" src={small ? ImageSmall : Image} alt="" />
      </section>
  );
}