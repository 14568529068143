import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import Loadable from 'react-loadable';
import PrivateRoute from '../../components/PrivateRoute';

const loading = () => <div/>;

const Login = Loadable({
  loader: () => import(/* webpackChunkName: "Login" */ '../Login'),
  loading,
  modules: ['Login'],
});
 
const RoutePass = Loadable({
  loader: () => import(/* webpackChunkName: "RoutePass" */ '../RoutePass'),
  loading,
  modules: ['RoutePass'],
});
const Cart = Loadable({
  loader: () => import(/* webpackChunkName: "Cart" */ '../Cart'),
  loading,
  modules: ['Cart'],
});

const AppRouter = function({ match }) {
  const isRouteCorrect = (window.location.href.includes('shopping-cart') || window.location.href.includes('buy-route-pass'));

  return (
    <Switch>
      <Route path={`${match.url}/login`} component={Login}/>
      <PrivateRoute path={`${match.url}/buy-route-pass`} component={RoutePass} />
      <PrivateRoute path={`${match.url}/shopping-cart`} component={Cart} />
      {isRouteCorrect ? null : <Redirect to={`${match.url}buy-route-pass`} />}
    </Switch>
  );
};

export default AppRouter;
