import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
const RequestsContext = React.createContext();

export const useRequestsContext = () => useContext(RequestsContext);

export function RequestsProvider({ children }) {
  const [activeRequests, setActiveRequests] = useState(0);

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      config => {
        setActiveRequests(activeRequests => activeRequests + 1);
        return config;
      },
      error => {
        setActiveRequests(activeRequests => activeRequests - 1);
        return Promise.reject(error);
      }
    );
    const responseInterceptor = axios.interceptors.response.use(
      config => {
        setActiveRequests(activeRequests => activeRequests - 1);
        return config;
      },
      error => {
        setActiveRequests(activeRequests => activeRequests - 1);
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);
  return (
    <RequestsContext.Provider value={{ activeRequests }}>
      { children }
    </RequestsContext.Provider>
  )
}