import * as StorageUtil from './storage';


const LANG_KEY = 'LANGUAGE';
const LANGUAGES = ['bg', 'en'];
export const DEFAULT_LANG = LANGUAGES[0];

function existingLang(lang){
	return LANGUAGES.includes(lang) ? lang : DEFAULT_LANG;
}

export function getWindowLocation(){
	return `${window.location.pathname}${window.location.pathname.substr(-1) === '/' ? '' : '/'}${window.location.search}`;
}

export function urlHasLang(url = getWindowLocation()){
	const path = url.split('/');
	return path.length > 2 && path[1].length === 2;
}

export function getPathWithLang(path = getWindowLocation(), lang = getLang()){
	return urlHasLang(path) ? `/${lang}${path.substr(3)}` : `/${lang}${path}`;
}

export function getLang() {
	const path = getWindowLocation();
	const lang = urlHasLang(path) ? existingLang(path.substr(1,2)) : (StorageUtil.getItem(LANG_KEY) || DEFAULT_LANG);
	StorageUtil.setItem(LANG_KEY, lang);
	return lang;
}

export function setLang(lang) {
	lang = existingLang(lang);
	StorageUtil.setItem(LANG_KEY, lang);
	window.history.replaceState( {} , '', getPathWithLang(getWindowLocation(), lang));
}