import React, { useEffect, useCallback, useRef } from 'react';

// a wrapper component to detect a mouse click outside the children
export default function ClickOutside({children, onClickOutside, ...others}){
  const el = useRef();

  const handleUserClick = useCallback((e) => {
    if (el && el.current && onClickOutside && !el.current.contains(e.target)) {
      onClickOutside();
    }
  }, [el, onClickOutside]);

  useEffect(() => {
    document.addEventListener("mousedown", handleUserClick);
    return () => {
      document.removeEventListener("mousedown", handleUserClick);
    }
  }, [handleUserClick]);

  return (
    <div {...others} ref={el}>
      {children}
    </div>
  );
}