import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useUserContext } from '../../contexts/userContext';
import { getPathWithLang } from '../../util/language';

export default function PrivateRoute({ component: Component, ...rest }) {
  const { user } = useUserContext();
  const path = getPathWithLang("/login");
  return (
    <Route
      {...rest}
      render={props =>
        user.id ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: path,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}