import React, { Fragment } from 'react';
import Link from '../../../components/LinkLng';
import { useVignetteContext } from '../../../contexts/vignetteContext';
import { useUserContext } from '../../../contexts/userContext';
import { useCustomerContext } from '../../../contexts/customerContext';
import { useLanguageContext } from '../../../contexts/languageContext';

export default function UserDropdown(props) {
  const { user, setUser } = useUserContext();
  const { gettext } = useLanguageContext();
  const { clearVignettes } = useVignetteContext();
  const { setCustomer } = useCustomerContext();

  const logOut = () => {
    clearVignettes();
    setUser({});
    setCustomer({});
  };
  
  const userLoggedIn = () => {
    return (
      <li className="menu-item dropdown-container">
        <Link to={user.customerId ? "/account/contract-management" : "/account/orderHistory"}>{user.names || user.username}</Link>
        <ul className="menu-dropdown">
          <Link className="menu-item" to="/account/orderHistory">{gettext('Моите поръчки')}</Link>
          {/*<Link className="menu-item" to="/account/myVehicles">{gettext('Моите превозни средства')}</Link>*/}
          {/* {user.customerId && <Link className="menu-item" to="/account/tolling-charges">{gettext('Тол задължения')}</Link>}
          {user.customerId && <Link className="menu-item" to="/account/tolling-payments">{gettext('Тол плащания')}</Link>} */}
          {user.customerId && <Link className="menu-item" to="/account/contract-management">{gettext('Управление на договори')}</Link>}
          {user.customerId && <Link className="menu-item" to="/account/toll-settings">{gettext('Настройки за Тол')}</Link>}
          {user.customerId && <Link className="menu-item" to="/account/route-pass-calculator">{gettext('Тол калкулатор')}</Link>}
          {user.customerId && <Link className="menu-item" to="/account/obu-delivery">{gettext('Бордови устройства')}</Link>}
          <Link className="menu-item" to="/account/settings">{gettext('Настройки')}</Link>
          <Link className="menu-item" to="/login" onClick={logOut}>{gettext('Изход')}</Link>
        </ul>
      </li>
    );
  };

  const userNotLoggedIn = () => {
    return (
      <Fragment>
        <li className="menu-item"><Link to="/login">{gettext('Влез')}</Link></li>
        <li className="menu-item"><Link to="/register" className="button button-primary button-narrow">{gettext('Регистрация')}</Link></li>
      </Fragment>
    );
  }

  return (
    <nav className="site-login">
      <Link to={user.customerId ? "/account/toll-settings" : "/account/orderHistory"} className="menu-icon menu-icon-profile">
        <div className="icon icon-user-outline icon-white"></div>
      </Link>
      <ul className="menu-links">
        { user.id ? userLoggedIn() : userNotLoggedIn() }
      </ul>
    </nav>
  );
}