import axios from 'axios';
import { BASE_URL } from '../util/constants';
import { resolveObj } from '../util/common';

const requestCache = {};

const endpoints = {
  erp: {
    master: {
      titles: {url: '/v1/c5/master/titles'},
      businessTypes: {url: '/v1/c5/master/businesstypes'},
      customerTypes: {url: '/v1/c5/master/customertypes'},
      countries: {url: '/v1/c5/master/countries'},
      regions: {url: '/v1/c5/master/regions/:code'},
      identificationTypes: {url: '/v1/c5/master/identificationtypes/:id'},
      emissionClasses: {url: '/v1/c5/master/emissionclasses'},
      vehicleClasses: {url: '/v1/c5/master/vehicleclasses'},
      inventoryTypes: {url: '/v1/c5/master/inventoryTypes'},
      fundingMethods: {url: '/v1/c5/master/fundingMethods'},
      languages: {url: '/v1/c5/master/languages'}
    }
  }
}

const doRequest = (url, server, data) => axios({ url, method: server.method || 'GET', data }).then(data => data.data ? data.data : data);

export const request = (what, data, cacheId=null) => {
  const server = resolveObj(what, endpoints);
  if (server) {
    const url = BASE_URL + server.url.split('/').map(str => str.startsWith(':') ? data[str.substr(1)] : str).join('/'); 
    if (cacheId != null) {
      if (!requestCache[what]) {
        requestCache[what] = {};
      }
      if (!requestCache[what][cacheId]) {
        requestCache[what][cacheId] = doRequest(url, server, data);
      }
      return requestCache[what][cacheId];
    } else {
      return doRequest(url, server, data);
    }
  } else {
    return null;
  }
}

export const requestMulti = (arr, setters) => {
  const promises = arr.map(what => request(what));
  axios.all(promises).then(res => res.forEach((data,i) => setters[i] && setters[i](data)));
}