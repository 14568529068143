import React, { useRef } from 'react';
import { useLanguageContext } from '../../contexts/languageContext';
import Link from '../../components/LinkLng';
import LanguagePicker from './LanguagePicker';

export default function Header() {
  const main = useRef(null);
  const { gettext } = useLanguageContext();

  return (
    <div className="site-header headroom headroom--top">
      <div className="site-header-main" ref={main}>
        <div className="row wide">
          <div className="column small-12">
            <div className="site-header-inner">
              <h1 className="site-logo"><Link to="/buy-route-pass">{gettext('DigiToll')}</Link></h1>
              <LanguagePicker/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
