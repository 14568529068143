import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { useLanguageContext } from '../../../contexts/languageContext';
import { useUserContext } from '../../../contexts/userContext';
import { useErpContext } from '../../../contexts/erpContext';
import { getPosesPagination, getAllPosesPagination, getUserPoses } from '../../Services/posService';
import { required } from '../../../util/form';
import MultiSelect from '../../../components/Form/MultiSelect';
import { debounce } from 'debounce';

export default function PosField({hideInactive=true, pageSize=50, showSingleValue, filterCondition, isDelict, ...others}){
  const { gettext } = useLanguageContext();
  const { user } = useUserContext();
  const { userAllowed, userIs, isUserDelict } = useErpContext();
  const [options, setOptions] = useState([]);
  const loadAll = !userAllowed('ROLE_PARTNER_ADMIN') && !userIs('ROLE_ACCOUNTANT');

  useEffect(() => {
    loadAll && getUserPoses().then(setOptions);
  }, []);

  const loadOptions = (value, cb) => {
    const params = {
      params:{
        hide_inactive: hideInactive,
        page_number: 0,
        page_size: pageSize,
        f_code: value
      }
    };
    if (userAllowed('ROLE_ADMIN') || (isDelict && isUserDelict())) {
      getAllPosesPagination(params).then(({data}) => cb(filterCondition ? data.elements.filter(opt => opt[filterCondition]) : data.elements));
    } else if (userAllowed('ROLE_PARTNER_ADMIN')) {
      getPosesPagination(params, user.partnerId).then(({data}) => cb(filterCondition ? data.elements.filter(opt => opt[filterCondition]) : data.elements));
    }
  };

  return (
    <Field component={MultiSelect}
      name="pos"
      label={gettext('APP.COMMON.COMMON.POS')}
      required
      validate={required}
      isSearchable
      isMulti={false}
      getOptionLabel={(option) => `${option.code} - ${option.name}`}
      initialValue={showSingleValue && (options.length === 1 ? options[0] : null)}
      {...others}
      {...(loadAll ? {options: options} : {loadOptions: debounce(loadOptions, 500)})}
    />
  );
};