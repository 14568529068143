import React, { useState, useContext, useEffect } from 'react';
import { useUserContext } from './userContext';
import { getRoles } from '../ERP/Services/userService';
import { getPoses, getAllPoses, getUserPoses } from '../ERP/Services/posService';
import { getPartners } from '../ERP/Services/partnerService';
import { userIsAllowed, getUserAccessLevel, userIsRole, isDelictUser } from '../util/user';
import { requestMulti } from '../services/networkService';
import { useLanguageContext } from './languageContext';
import { CONFIG } from '../config';

const BULGARIA_CODE = "BG"
const TURKEY_CODE = "TR"
const IRAN_CODE = "IR"
const ROMANIA_CODE = "RO"
const SERBIA_CODE = "RS"
const MOST_COMMON_COUNTRIES = [BULGARIA_CODE, TURKEY_CODE, IRAN_CODE, ROMANIA_CODE, SERBIA_CODE];

const ErpContext = React.createContext();

export const useErpContext = () => useContext(ErpContext);

export function ErpProvider({ children }) {
  const { gettext, language } = useLanguageContext();
  const { user } = useUserContext();
  const [ partners, setPartners ] = useState([]);
  const [ loadPartners, setLoadPartners ] = useState(false);
  const [ roles, setRoles ] = useState([]);
  const [ poses, setPoses ] = useState([]);
  const [ userAccessLevel, setUserAccessLevel ] = useState(0);

  const [ c5Titles, setC5Titles ] = useState([]);
  const [ c5Countries, setC5Countries ] = useState([]);
  const [ c5ReorderedCountries, setC5ReorderedCountries ] = useState([]);

  // const [ c5BusinessTypes, setC5BusinessTypes ] = useState([]);
  const [ c5CustomerTypes, setC5CustomerTypes ] = useState([]);
  const [ c5EmissionClasses, setC5EmissionClasses ] = useState([]);
  const [ c5VehicleClasses, setC5VehicleClasses ] = useState([]);
  const [ c5InventoryTypes, setC5InventoryTypes ] = useState([]);
  const [ c5FundingMethods, setC5FundingMethods ] = useState([]);
  const [ c5Languages, setC5Languages ] = useState([]);

  const userAllowed = (minRole, accessLevel=userAccessLevel) => {
    return userIsAllowed(minRole, accessLevel);
  };

  const ERP = process.env.REACT_APP_TYPE === 'ERP';

  const userIs = (role, accessLevel=userAccessLevel) => userIsRole(role, accessLevel);

  const isUserDelict = () => isDelictUser();

  const roleList = (arr, accessLevel=userAccessLevel) => arr.some(el => userIs(el));

  const sortName = (param) => (a,b) => {
    const x = a[param] ? a[param].toLowerCase() : '';
    const y = b[param] ? b[param].toLowerCase() : '';
    return x > y ? 1 : ((y > x) ? -1 : 0);
  };

  const refreshPartners = (accessLevel = userAccessLevel) => {
    if (userAllowed('ROLE_ADMIN', accessLevel) || userIs('ROLE_ACCOUNTANT', accessLevel)) {
      const params = {
        params: {
          page_number: 0,
          page_size: 15000,
          sorting_direction: 'ASC',
          sorting_parameter: 'name'
        }
      }
      getPartners(params).then(data => {
        setPartners(data);
      });
    }
  }

  const refreshPoses = (accessLevel=userAccessLevel) => {
    if (userAllowed('ROLE_ADMIN', accessLevel)) {
      getAllPoses().then(setPoses);
    } else if (userAllowed('ROLE_PARTNER_ADMIN', accessLevel)) {
      getPoses(user.partnerId).then(setPoses);
    } else if (!userIs('ROLE_ACCOUNTANT', accessLevel)) {
      getUserPoses().then(setPoses);
    }
  }

  const loadC5Data = (access) => {

    setC5CustomerTypes([
      {"id":1,"code":1,"description":gettext('Индивидуален')},
      {"id":2,"code":2,"description":gettext('Бизнес')}
    ]);

    setC5Titles([
      {"id":1,"description":gettext("Г-н")},
      {"id":2,"description":gettext("Г-жа")},
      // {"id":3,"description":gettext("Mrs")},
      // {"id":4,"description":gettext("Miss")}
    ]);

    // setC5BusinessTypes([
    //   {"id":1,"description":"Business Type 1","default":true,"active":true},
    //   {"id":2,"description":"Business Type 2","default":false,"active":true},
    //   {"id":3,"description":"Business Type 3","default":false,"active":true}
    // ]);

    setC5EmissionClasses([
      {"id":0,"description":"Euro 0"},
      {"id":1,"description":"Euro 1"},
      {"id":2,"description":"Euro 2"},
      {"id":3,"description":"Euro 3"},
      {"id":4,"description":"Euro 4"},
      {"id":5,"description":"Euro 5"},
      {"id":6,"description":"Euro 6"},
      {"id":15,"description":"EEV"}
    ]);

    setC5VehicleClasses([
      {"id":1,"description":gettext("Мотоциклет")},
      {"id":2,"description":gettext("Лек автомобил")},
      {"id":3,"description":gettext("Автобус (UNECE клас М2, М3)")},
      {"id":4,"description":gettext("Превозно средство между 3.5 и 12 т (UNECE клас N2 )")},
      {"id":5,"description":gettext("Превозно средство над 12 т (UNECE клас N3 )")},
      {"id":7,"description":gettext("Други превозни средства над 3.5 т невключени в останалите групи")}
    ]);

    setC5InventoryTypes([
      {"code":"OBU","id":1,"description":gettext("ОБУ")},
      {"code":"MYF","id":2,"description":gettext("MyFare")},
      {"code":"Tracker","id":3,"description":gettext("Устройство за проследяване")},
      {"code":"MYF2","id":4,"description":gettext("MyFare(Sale)")},
      {"code":"TAG3","id":5,"description":gettext("Tag (DSRC)(NonReusable)")},
      {"code":"MYF3","id":6,"description":gettext("MyFare(NonReusable)")},
      //{"code":"NONE","id":999,"description":gettext("None")}
    ]);

    setC5FundingMethods([
      {"id":1,"code":1,"description":gettext("Ръчно")},
      {"id":2,"code":2,"description":gettext("Автоматично")}
    ]);

    if (userAllowed('ROLE_ADMIN', access) && CONFIG.ENABLE_TOLLING) {
      requestMulti([
        //'erp.master.customerTypes', 
        //'erp.master.titles',
        //'erp.master.businessTypes',
        'erp.master.countries',
        //'erp.master.emissionClasses',
        //'erp.master.vehicleClasses',
        //'erp.master.inventoryTypes',
        //'erp.master.fundingMethods',
        'erp.master.languages'
      ],[
        //setC5CustomerTypes,
        //setC5Titles,
        //setC5BusinessTypes,
        res => {
          let newRes = res.map(({code, description, translations}) => {
            const obj = translations.find(t => t.ietfCode === "bg-BG");
            return {code, description: (language === 'bg' && obj) ? obj.description : description };
          });
          newRes.sort(sortName('description'))
          const indexBG = newRes.map(el => el.code).indexOf(BULGARIA_CODE);
          const reordered = indexBG ? [newRes[indexBG], ...newRes.slice(0, indexBG), ...newRes.slice(indexBG+1)] : newRes;
          setC5Countries(reordered);

          const indexesOfCommonCountries = MOST_COMMON_COUNTRIES.map(code => newRes.map(el => el.code).indexOf(code));

          if (indexesOfCommonCountries.every(index => index !== -1)) {
            const newResFiltered = newRes.filter((_, index) => !indexesOfCommonCountries.includes(index));
            const commonUsedCountries = [
              ...indexesOfCommonCountries.map(index => newRes[index]),
              ...newResFiltered
            ];
            newRes = commonUsedCountries;
            setC5ReorderedCountries(newRes);
          } else {
            setC5ReorderedCountries(reordered);
          }
      },
        //setC5EmissionClasses,
        //setC5VehicleClasses,
        //setC5InventoryTypes,
        //setC5FundingMethods,
        setC5Languages
      ]);
    }
  }

  useEffect(() => {
    if (ERP) { 
      if (user.token && user.id) {
        const access = getUserAccessLevel(user.roles);
        setUserAccessLevel(access);

        if (!roles.length && userAllowed('ROLE_ADMIN', access)) {
          getRoles().then(res => {
            const allowedRoles = userIs('ROLE_CONCORD_ADMIN', access) ? 
              res.filter(r => !['ROLE_CONCORD_ADMIN', 'ROLE_ADMIN'].includes(r.code)) : 
              res;
            setRoles(allowedRoles.sort(sortName('name')));
          });
        }
        if (!poses.length) {
          refreshPoses(access);
        }
        loadC5Data(access);
      } else {
        setRoles([]);
        setPoses([]);
      }
    }
  }, [user.token, user.id]);

  useEffect(() => {
    if (ERP) {
      if (loadPartners) {
        refreshPartners(userAccessLevel);
      } else {
        setPartners([]);
      }
    }
  }, [loadPartners]);

  return (
    <ErpContext.Provider value={{
      partners,
      roles,
      poses,
      userAllowed,
      userIs,
      isUserDelict,
      roleList,
      refreshPartners,
      refreshPoses,
      setLoadPartners,
      c5Titles,
      c5Countries,
      c5ReorderedCountries,
      // c5BusinessTypes,
      c5CustomerTypes,
      c5EmissionClasses,
      c5VehicleClasses,
      c5InventoryTypes,
      c5FundingMethods,
      c5Languages
    }}>
      {children}
    </ErpContext.Provider>
  );
}