import React, { useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';

export default function Popup({ portal, open, title, children, buttons, onClose, className, disableBackdropClick = false, disableClose = false, isCloseButtonVisible= true }) {

  const handleClose = () => {
    if (!disableClose) {
      onClose();
    }
  };

  const handleUserKeyPress = useCallback(({ keyCode }) => {
    if (keyCode === 27 && open) {
      handleClose();
    }
  }, [open]);

  useEffect(() => {
    document.addEventListener("keyup", handleUserKeyPress);
    return () => {
      document.removeEventListener("keyup", handleUserKeyPress);
    }
  }, [handleUserKeyPress]);

  const renderPopup = (
    <div data-testid="popUp" className={`popup ${process.env.REACT_APP_TYPE === 'ERP' ? 'popup-erp' : ''} ${className ? className : ''}`}>
      <div className={`popup-wrapper${open ? ' is-visible' : ''}`}>
        <div className="popup-box">
          <div className="popup-title">
            {title}
          </div>
          {isCloseButtonVisible && <span className="popup-close" onClick={handleClose} />}
          {children}
          <div className="popup-buttons">
            {buttons}
          </div>
        </div>
        <div className="popup-backdrop" onClick={disableBackdropClick ? () => {} : handleClose}/>
      </div>
    </div>
  );

  return portal ? ReactDOM.createPortal(renderPopup, document.body) : renderPopup;
}