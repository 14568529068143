import { FORM_ERROR } from 'final-form';

export function generateFilterParams(filters, map) {
  return filters.reduce((acc, obj) => {
    const mapped = map[obj.id];
    if (mapped) {
      if (typeof mapped === 'object'){
        return {...acc, ...Object.keys(mapped).reduce((a, k) => ({...a, [mapped[k]]: obj.value[k]}), {})};
      } else {
        return {...acc, [mapped]: obj.value};
      }
    } else {
      return acc; 
    }
  }, {}); 
}

export function downloader(outfile) {
  return (response) => {
    const header = response.headers['content-disposition'];
    const filename = header ? /filename="(.+)?"/g.exec(header)[1] : outfile;
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var csvData = new Blob([response.data], { type: 'text/csv' });
      window.navigator.msSaveOrOpenBlob(csvData, filename);
    } else {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    }
  }
}

export function handleErrorMessage(err) {
  const handleMessage = data => data.message && isNaN(data.message.trim()) ? data.message : 'Неустановена грешка';

  return (err.response && err.response.data) ? 
    (
      err.response.data.errors && err.response.data.errors.length ? 
      err.response.data.errors[0].message : 
      handleMessage(err.response.data)
    ) : handleMessage(err);
}

export function handleErrors(err) {
  return { [FORM_ERROR]: handleErrorMessage(err) };
}

export function handleStatusErrors(err) {
  const handleErrorStatus = err => err.response.status;
  return { [FORM_ERROR]: handleErrorStatus(err) };
}

export function S3Downloader(filename){
  return (response) => {
    let blob = new Blob([response.data], {type: response.headers['content-type']});
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  }
}