const iconsMap = {
    car: 'category3_light_vehicle',
    trailer: 'category3_caravan',
    hgvn2: 'category2_truck_bus',
    hgvn3: 'category1_heavy_truck'
};

/*
* This method transforms the prices options from thе backend that are a flat structure into the tree
* that the rendering supports. This may be refactored in future as a structure of normalized JSON but
* significant changes to the rendering will be needed.
* */
export default function buildCategories(response) {
    const vehicleTypes = new Set();
    return response.reduce((acc, { vehicleType, ...price }) => {
        if(!vehicleTypes.has(vehicleType)){
            vehicleTypes.add(vehicleType);
            return [
                ...acc,
                {
                    category: vehicleType,
                    description: price.vehicleTypeText,
                    icon: iconsMap[vehicleType] || '',
                    displayName: price.categoryDescriptionText,
                    emissionClass: response
                        .filter(p => p.vehicleType === vehicleType)
                        .reduce((acc, p) => {
                            if (!acc.some(({ title }) => title === p.emissionClass)) {
                                return [...acc, {
                                    title: p.emissionClass,
                                    displayName: p.emissionClass ? p.emissionClassText : '',
                                    periods: response
                                        .filter(p1 => p1.emissionClass === p.emissionClass && vehicleType === p1.vehicleType)
                                        .sort((p1, p2) => p1.price.amount - p2.price.amount)
                                        .map(p => ({
                                            title: p.validityType,
                                            price: `${p.price.amount} ${p.price.currency}`,
                                            displayName: p.validityTypeText,
                                            id: p.id
                                        }))
                                }]
                            } else {
                                return acc;
                            }
                        }, [])
                }
            ]
        } else {
            return acc;
        }
    }, []);
}