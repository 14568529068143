import React from 'react';
import { LanguageProvider } from './languageContext';
import { UserProvider } from './userContext';
import { CustomerProvider } from './customerContext';
import { VignetteProvider } from './vignetteContext';
import { CountriesProvider } from './countriesContext';
import { ErpProvider } from './erpContext';
import { RequestsProvider } from './requestsContext';
import { PopupProvider } from './popupContext';
import { MenuProvider } from './menuContext';
import FeaturesWrapper from "../components/Features";
import {FeaturesProvider} from "./featuresContext";

export default function MasterProvider({ children }) {
  return (
      <FeaturesProvider>
      <RequestsProvider>
        <LanguageProvider>
          <UserProvider>
            <VignetteProvider>
              <CountriesProvider>
                <ErpProvider>
                  <MenuProvider>
                    <CustomerProvider>
                      <PopupProvider>
                        <FeaturesWrapper>
                          {children}
                        </FeaturesWrapper>
                      </PopupProvider>
                    </CustomerProvider>
                  </MenuProvider>
                </ErpProvider>
              </CountriesProvider>
            </VignetteProvider>
          </UserProvider>
        </LanguageProvider>
      </RequestsProvider>
    </FeaturesProvider>
  );
}