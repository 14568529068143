import axios from 'axios';
import { BASE_URL } from '../../util/constants';
import { downloader } from '../../util/request';

export function getPoses(partnerId) {
  return axios.get(`${BASE_URL}/pos/${partnerId}/all`, {params: {show_archived: false}}).then(data => data.data.elements);
}

export function getAllPoses() {
  return axios.get(`${BASE_URL}/pos`, {params: {hide_inactive: true}}).then(data => data.data.elements);
}

export function getUserPoses() {
  return axios.get(`${BASE_URL}/pos/user/all`).then(data => data.data);
}

export function getPosesPagination(params, partnerId) {
  return axios.get(`${BASE_URL}/pos/${partnerId}/all`, params);
}

export function getAllPosesPagination(params) {
  return axios.get(`${BASE_URL}/pos`, params);
}

export function addPos(data) {
  return axios.post(`${BASE_URL}/pos`, data);
}

export function updatePos(data) {
  return axios.put(`${BASE_URL}/pos/${data.id}`, data);
}

export function posActivate(posId) {
  return axios.post(`${BASE_URL}/pos/restore/${posId}`);
}

export function posDeactivate(posId) {
  return axios.delete(`${BASE_URL}/pos/${posId}`);
}

export function openShift(data) {
  return axios.post(`${BASE_URL}/cashiershift/start`, data);
}

export function closeShift(id) {
  return axios.post(`${BASE_URL}/cashiershift/end/${id}`);
}

export function getCashierShifts(params) {
  return axios.get(`${BASE_URL}/cashiershift`, params);
}

export function getCashierShiftById(id) {
  return axios.get(`${BASE_URL}/cashiershift/${id}`);
}

export function getCashierShiftStatusById(id) {
  return axios.get(`${BASE_URL}/cashiershift/status/${id}`);
}

export function getCashierShiftForUser() {
  return axios.get(`${BASE_URL}/cashiershift/user`);
}

export function getCashierShiftSales(options, cashierShiftId) {
  const defaultParams = {
    page_number: options.page,
    page_size: options.pageSize,
    sorting_direction: options.sorted[0].desc ? 'DESC':'ASC',
    sorting_parameter: options.sorted[0].id,
  };
  return axios.get(`${BASE_URL}/cashiershift/sales/${cashierShiftId}`, {params: defaultParams});
}

export function checkActiveCashierShifts() {
  return axios.get(`${BASE_URL}/cashiershift/user`);
}

export function exportDetailedShipmentsCsv(cashierShiftId) {
  return axios({
    url: `${BASE_URL}/cashiershift/detailedReport/${cashierShiftId}`,
    method: 'GET',
    responseType: 'blob',
  }).then(downloader('DetailedReportShifts.csv'));
}

export function exportSummarizedShipmentsExcel(cashierShiftId) {
  return axios({
    url: `${BASE_URL}/cashiershift/summarizedReport/${cashierShiftId}`,
    method: 'GET',
    responseType: 'blob',
  }).then(downloader('SummarizedReportShifts.xls'));
}