import React, { Fragment } from 'react';
import TabPanel from '../../../components/TabPanel';
import { FORM_ERROR } from 'final-form';
import { useLanguageContext } from '../../../contexts/languageContext';
import { resolveObj } from '../../../util/common';
import loader from '../../../images/loader.gif';

export default function EditForm({formRef, formProps, tabs, readOnly, editMode, activeSaveButton, disableSaveButton = false, elementId , defaultTab, hasScroll, setCurrentTabIndex}) {
  const { gettext } = useLanguageContext();
  const invalid = formProps.submitError && !formProps.dirtySinceLastSubmit && formProps.submitErrors;

  const tabError = (prop) => {
    if (prop) {
      const err = resolveObj(prop, formProps.errors);
      const res = (err && formProps.dirty && (editMode ? true : formProps.submitFailed)) ? err[0] : false;
      const result = (typeof res === 'object' && res !== null) ? res[Object.keys(res)[0]] : res;
      return result;
    }
    return false;
  };

  const validatedTabs = tabs.map(tab => ({ ...tab, error: tabError(tab.errorProp) }));

  return (
    <form
        ref={formRef}
        className="box-form" onSubmit={formProps.handleSubmit}>
      <TabPanel tabs={validatedTabs} renderAllTabs={!readOnly} elementId={elementId} defaultTab={defaultTab} hasScroll={hasScroll} setCurrentTabIndex={setCurrentTabIndex}>
        {!readOnly && (
          <Fragment>
            <div style={{display: 'flex', textAlign: 'center'}}>
            {formProps.hasSubmitErrors &&
              <span className="tab-error" title={formProps.submitErrors[FORM_ERROR]}>
                {formProps.submitErrors[FORM_ERROR]}
              </span>
            }
            <button
              className="icon-button"
              title={gettext(invalid ? 'Невалидни данни' : 'Запазване на промените')}
              type="submit"
              disabled={disableSaveButton || ((formProps.submitting || invalid || formProps.hasValidationErrors || formProps.pristine) && !activeSaveButton)}
            >
              {formProps.submitting ? <img src={loader} alt="" /> : <div className="icon icon-floppy-o" />}
            </button>
            </div>
          </Fragment>
        )
        }
      </TabPanel>
    </form>
  );
}