import React from 'react';
import './app.scss';
import MasterProvider from '../../contexts';
import Header from '../Header';
import ErrorBoundary from '../../components/ErrorBoundary';
import AppRouter from '../AppRouter';
import { withRouter, Route } from 'react-router';
import Footer from '../Footer';
import { CONFIG } from "../../config";
import Features from "../Features";

class App extends React.Component {

    componentDidUpdate(prevProps){
        if (typeof window.gtag !== "undefined"  &&
            window.GTAG_ID &&
            this.props.location !== prevProps.location.pathname
        ) {
            window.gtag("config", CONFIG.GTAG_ID, {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
        }
    }

    injectGA = () => {
        if (typeof window == 'undefined') {
            return;
        }

        if (typeof window.GTAG_ID !== 'undefined') {
            return;
        }

        if (typeof CONFIG.GTAG_ID == 'undefined') {
            return;
        }

        window.GTAG_ID = CONFIG.GTAG_ID;

        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }
        window.gtag = gtag;
        gtag('js', new Date());

        gtag('config', CONFIG.GTAG_ID);
    };

    injectGTM = () => {
        if (typeof CONFIG.GTM_ID == 'undefined') {
            return;
        }

        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer', 'GTM-PQBQJSP');
    }

    render() {
        const showHader = !this.props.location.search.includes('noheader');

        return (
            <ErrorBoundary>
                {/* <script>{this.injectGA()}</script> */}
                <script>{this.injectGTM()}</script>
                <MasterProvider>
                    {/*SNOW FLAKES*/}
                    {/* <div class="snowflakes" aria-hidden="true">
                        <div class="snowflake">
                        ❅
                        </div>
                        <div class="snowflake">
                        ❅
                        </div>
                        <div class="snowflake">
                        ❆
                        </div>
                        <div class="snowflake">
                        ❄
                        </div>
                        <div class="snowflake">
                        ❅
                        </div>
                        <div class="snowflake">
                        ❆
                        </div>
                        <div class="snowflake">
                        ❄
                        </div>
                        <div class="snowflake">
                        ❅
                        </div>
                        <div class="snowflake">
                        ❆
                        </div>
                        <div class="snowflake">
                        ❄
                        </div>
                    </div> */}
                    {/*SNOW FLAKES*/}
                    { showHader && <Header /> }
                    <Route path="/:lang/" component={AppRouter} />
                    { showHader && <Footer /> }
                </MasterProvider>   
                <noscript>
                    <iframe 
                        src="https://www.googletagmanager.com/ns.html?id=GTM-PQBQJSP" 
                        height="0" 
                        width="0" 
                        style="display:none;visibility:hidden">
                    </iframe>
                </noscript>
            </ErrorBoundary>
        );
    }
}

export default withRouter(App);