import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguageContext } from '../../../contexts/languageContext';
import { useErpContext } from '../../../contexts/erpContext';

export default function DesktopMenu({items}) {
  const { language } = useLanguageContext();
  const { userAllowed } = useErpContext();

  const generateMenu = (children, expandable) => children.map((item, key) => {

    const showItem = () =>  item.access != null ? (
      (typeof item.access === 'string' || item.access instanceof String) ? userAllowed(item.access) : item.access
    ) : true;

    return (
      showItem() && 
      <li key={key} {...item.props}>
        {
          item.link ?
          <Link to={`/${language}${item.link}`}>{item.title}</Link> :
          <>
            <span>{item.title}</span>
            <ul>
              {item.items && generateMenu(item.items)}
            </ul>
          </>
        }
      </li>
    )
  });

  return (
    <ul className="menu-main">
      { generateMenu(items) }
    </ul>
  );
}