import React, { useState, useContext } from 'react';
import { getUser, setUser } from '../util/user';

const UserContext = React.createContext();

export const useUserContext = () => useContext(UserContext);

export function UserProvider({ children }) {
  const [user, setUserToState] = useState(getUser());
  const [askToRegister, setAskToRegister] = useState(true);

  return (
    <UserContext.Provider value={{
      user,
      setUser: user => {
        setUser(user);
        setUserToState(user);
      },
      askToRegister, 
      setAskToRegister
    }}>
      {children}
    </UserContext.Provider>
  );
}
