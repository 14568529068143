import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import { BrowserRouter as Router } from "react-router-dom";
import './sass/master.scss';
import './index.css';

import App from './components/App';
import AppERP from './ERP/App';
import AppErpTablet from './ERP-Tablet/App';
// import * as serviceWorker from './serviceWorker';

window.onload = () => {
  Loadable.preloadReady().then(() => {
    ReactDOM.render(
      <Router >
      	{ getAppRoot() }
      </Router>,
      document.getElementById('root')
    );
  });

};

function getAppRoot() {
  const reactAppType = process.env.REACT_APP_TYPE;

  if(reactAppType === 'ERP') {
    return <AppERP />;
  } else if(reactAppType === 'ERPTABLET') {
    return <AppErpTablet/>
  }

  return <App />;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
