import React, {useEffect, useRef, useState} from 'react';
import '../../components/App/app.scss';
import MasterProvider from '../../contexts';
import Header from '../Header';
import ErrorBoundary from '../../components/ErrorBoundary';
import AppRouter from '../AppRouter';
import { withRouter, Route } from 'react-router';

function AppERP() {

    return (
    <div className="app-erp">
      <ErrorBoundary>
        <MasterProvider>
          <Header />
          <Route path="/:lang/" component={AppRouter} />
        </MasterProvider>
      </ErrorBoundary>
    </div>
  );
}

export default withRouter(AppERP);