import React, { useState } from 'react';
import { useLanguageContext } from '../../../contexts/languageContext';
import { LANGUAGES } from '../../../util/constants';
import LanguageFlag from '../LanguageFlag';

export default function LanguagePicker(props) {
    const [hover, setHover] = useState(false);
    const { setLanguage } = useLanguageContext();

    const handleClick = (languageKey) => {
        setHover(false);
        setLanguage(languageKey);
    }

    const renderLanguageOptions = (options) => options.map(({ lang, text, icon }) => (
        <li key={lang} onClick={() => handleClick(lang)}>
            <div>
                <LanguageFlag lang={lang} showText/>
            </div>
        </li>));

    return (
        <div className={`menu-language-picker ${hover ? 'menu-language-picker-hover' : ''}`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <LanguageFlag />
            <ul className="language-dropdown">
                {renderLanguageOptions(LANGUAGES)}
            </ul>
        </div>);
}