import * as StorageUtil from './storage';


const USER_KEY = 'user';

export function clearUser() {
  StorageUtil.setItem(USER_KEY, '{}');
}

export function getUser() {
  return JSON.parse(StorageUtil.getItem(USER_KEY) || '{}');
}

export function setUser(user) {
  StorageUtil.setItem(USER_KEY, JSON.stringify(user));
}

const USER_ROLES = [
  'ROLE_RIA',
  'ROLE_USERS',
  'ROLE_C2',
  'ROLE_VIGNETTE_USER',
  'ROLE_ROUTE_PASS_USER',
  'ROLE_C9',
  'ROLE_ACCOUNTANT',
  'ROLE_CENTRAL_WAREHOUSE_ADMIN',
  'ROLE_KAPSCH_OBU_SERVICE',
  'ROLE_CUSTOMERS_READ',
  'ROLE_CUSTOMERS_WRITE',
  'ROLE_OBU_READ',
  'ROLE_OBU_WRITE',
  'ROLE_UNITS_READ',
  'ROLE_UNITS_WRITE',
  'ROLE_HANDOUT_WRITE',
  'ROLE_NOTIFICATIONS_READ',
  'ROLE_INVOICE_PAYMENTS',
  'ROLE_EDIT_INVOICES',
  'ROLE_VIEW_INVOICES',
  'ROLE_VIEW_APIS',
  'ROLE_PARTNER_ADMIN',
  'ROLE_ADMIN',
  'ROLE_CONCORD_ADMIN',
  'ROLE_TOLL_ADMIN',
];

export function userIsRole(role, accessLevel) {
  return accessLevel === USER_ROLES.indexOf(role);
}

export function userIsAllowed(minRole, accessLevel) {
  const roleIndex = USER_ROLES.indexOf(minRole);
  return (roleIndex >= 0) ? (accessLevel >= roleIndex) : false;
};

export function getUserAccessLevel(roles) {
  let result = 0;
  roles && roles.forEach(role => {
    let index = USER_ROLES.indexOf(role.code);
    if (index > result) {
      result = index;
    } 
  });
  return result;
}

export function isFiscal(){
  return getUser().roles.find(role => role.code === 'ROLE_FISCAL_DEVICE');
}

export function isDelictUser(){
  const userRole = getUser().roles;

  if (!userRole) {
    return;
  }
  return !!userRole.find(role => role.code === 'ROLE_DELICT_USER');
}

export function userHasRole(roleName){
  const userRole = getUser().roles;

  if (!userRole) {
    return;
  }
  return !!userRole.find(role => role.code === roleName);
}