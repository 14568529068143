import React, {Fragment, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import devTools from "devtools-detect";
import {md5} from "request/lib/helpers";
import {FEATURES_KEY} from "../../util/constants";
import {useFeaturesContext} from "../../contexts/featuresContext";


export function FeaturesElements() {
    const { featuresData, removeFeaturesHandle, setFeaturesHandle } = useFeaturesContext();
    const [features, setFeatures] = useState({});

    useEffect(() => {
        setFeatures(featuresData);
    }, [featuresData])


    const onSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();
        removeFeaturesHandle();
    }

    const onChange = (featureKey) => {
        let tempFeatures = features;
        tempFeatures[featureKey] = !tempFeatures[featureKey];
        setFeaturesHandle(tempFeatures);
    }

    return (
        <div className={"features-overlay"}>
            <form className="site-content" onSubmit={onSubmit}>
                <section className="section-fullwidth section-register">
                    <div className="row">
                        <div className="column">
                            {Object.keys(features).map((featureKey) => {
                                return (
                                    <div className="input-group-checkbox" key={featureKey}>
                                        <input name={featureKey}
                                               type="checkbox"
                                               id={featureKey}
                                               checked={features[featureKey]}
                                               onChange={() => {
                                                   onChange(featureKey)
                                               }}/>
                                        <label htmlFor={featureKey}>{featureKey}</label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="row">
                        <button title="Reset" className='add-segment-button' type="submit">Reset</button>
                    </div>
                </section>
            </form>
        </div>
    )

};

export function Features(props) {

    return (
        <Fragment>
            {ReactDOM.createPortal(<FeaturesElements/>, document.getElementById("overlay"))}
        </Fragment>
    )
};


export default function FeaturesWrapper({children}) {

    const [keysPressed, setKeysPressed] = useState("{}");
    const [showFeatures, setShowFeatures] = useState(false);

    const handleKeyDown = event => {
        if (event.key == "Control" ||
            event.key == "ArrowDown" ||
            event.key == "ArrowUp") {
            let tempKeyPressed = JSON.parse(keysPressed);
            tempKeyPressed[event.key] = true;
            setKeysPressed(JSON.stringify(tempKeyPressed));
        }
    };

    const handleKeyUp = event => {
        if (event.key == "Control" ||
            event.key == "ArrowDown" ||
            event.key == "ArrowUp") {
            let tempKeyPressed = JSON.parse(keysPressed);
            tempKeyPressed[event.key] = false;
            setKeysPressed(JSON.stringify(tempKeyPressed));

        }
    };


    useEffect(() => {
        if (devTools.isOpen) {
            let keys = JSON.parse(keysPressed);
            if (keys["Control"] && keys["ArrowDown"]) {
                setShowFeatures(false);
                keys["ArrowUp"] = false;
                keys["ArrowDown"] = false;
            }
            if (keys["Control"] && keys["ArrowUp"]) {
                let pass = sessionStorage.getItem("feature_key");
                if (pass && pass !== FEATURES_KEY) {
                    pass = null;
                }
                if (!pass) {
                    pass = prompt("Password:", "");
                    keys["ArrowUp"] = false;
                    keys["ArrowDown"] = false;
                    setKeysPressed(JSON.stringify(keys));
                    if (!pass) pass = "";
                    pass = md5(pass);
                }
                if (pass && pass === FEATURES_KEY) {
                    sessionStorage.setItem("feature_key", pass);
                    setShowFeatures(true);
                }
            }
        }

    }, [
        keysPressed
    ])


    return (
        <Fragment>
            <div tabIndex={0} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} style={{"height":"100%"}}>
                {children}
                {showFeatures && <Features/>}
            </div>
        </Fragment>
    )
};