import axios from 'axios';
import { BASE_URL } from '../util/constants';
import buildCategories from '../util/prices';
import {downloader} from '../util/request';
import {productTypes} from '../util/constants';


export function getVignettePrices() {
    return axios.get(`${BASE_URL}/products`)
        .then(response => buildCategories(response.data));
}

export function buyVignette(data) {
    return axios.post(`${BASE_URL}/purchasePayment`, data);
}

export function checkVignette(countryCode, lpn, socialVignette, captcha) {
	if (typeof socialVignette === "undefined") {
		socialVignette = false;
	}
    return axios.get(`${BASE_URL}/vignette/validateapi/?countryCode=${countryCode}&lpn=${lpn}&isSocial=${socialVignette}&recaptchaCode=${captcha}`)
        .then(({ data }) => data);
}

export function subscribeVignetteExpirationNotification(data,recaptchaCode) {
	return axios({
		url: `${BASE_URL}/vignette/emails`,
		method: 'POST',
		params: {recaptchaCode},
		data: data
	})
}

export function unsubscribeNotifications(data, captcha) {
	return axios({
		url: `${BASE_URL}/unsubscribe?recaptchaCode=${captcha}`,
		method: 'POST',
		data: data
	})
}
export function unsubscribeNotificationsConfirm(data) {
	return axios({
		url: `${BASE_URL}/unsubscribe-confirm`,
		method: 'POST',
		data: data
	})
}

export function getDataAfterSuccessfulPurchase(transactionId) {
    return axios.get(`${BASE_URL}/sale?trans_id=${encodeURIComponent(transactionId)}`);
}

export function downloadPdf(row) {
	let pdfUrl = `${BASE_URL}/generatePdf?vignette_id=${encodeURIComponent(row.vignetteId)}`;
	let fileName = `${row.vignetteId}.pdf`;

	if (row.productType == productTypes.compensatoryFee) {
		pdfUrl = `${BASE_URL}/generate-pdf-compensatory-fee?delict_document_number=${encodeURIComponent(row.delictDocumentNumber)}`;
		fileName = `${row.delictDocumentNumber}.pdf`;
	}

	if (row.productType == productTypes.routePass) {
		pdfUrl = `${BASE_URL}/generate-pdf-route-pass?route_pass_id=${encodeURIComponent(row.routePassID)}`;
		fileName = `${row.routePassID}.pdf`;
	}

	axios({
		url: pdfUrl,
		method: 'GET',
		responseType: 'blob'
	}).then(downloader(fileName));
}

export function getVignetteInformationBeforeCheckout(vignetteInfo) {
	const url = `${BASE_URL}/active-vignettes-check`;
	return axios.post(url, vignetteInfo);
}
