import React from 'react';
import { useLocation} from "react-router";
import { Link } from 'react-router-dom';
import { useLanguageContext } from '../../contexts/languageContext';

export default function LinkLng({to, children, external, ...others}) {
  const hideLinks = useLocation().search.includes('noheader');
  const { language } = useLanguageContext();

  const dst = (typeof to === 'string' || to instanceof String) ? 
    `/${language}${to.startsWith('/') ? '' : '/'}${to}` : to;

  return (hideLinks ? children : (
  	external ? 
  		<a href={to} target="new">{children}</a> :
  		<Link to={dst} {...others}>{children}</Link>
  	));
}