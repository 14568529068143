import React from 'react';
import { Form, Field } from 'react-final-form';
import { useLanguageContext } from '../../../contexts/languageContext';
import { composeValidators, required } from '../../../util/form';
import TextField from '../../../components/Form/TextField';

export default function PopupQuery({text, onSubmit, onClose, validator}) {
  const { gettext } = useLanguageContext();

  const submit = ({name}) => {
    onSubmit(name);
  };

  return (
    <Form
      onSubmit={submit}
      render={({values, handleSubmit, ...formProps }) => {
        return (
          <form className="box-form" onSubmit={handleSubmit}>
            <div className="field-container">
              <div className="form-inline form-inline-border"> 
                <Field
                  name="name"
                  component={TextField}
                  type="text"
                  label={text}
                  required
                  validate={composeValidators(required, validator)}
                />
              </div>

              <div className="popup-buttons">
                <button type="button" className="button button-primary button-ghost" onClick={onClose}>{gettext('Затвори')}</button>
                <button className="button button-primary">{gettext('ОК')}</button>
              </div>
              
            </div>
          </form>
        );
      }
    } />
  );
}