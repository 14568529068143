import React, { useState } from 'react';
import { useLanguageContext } from '../../../contexts/languageContext';
import { LANGUAGES } from '../../../util/constants';
import LanguageFlag from '../LanguageFlag';
import nonModuleStyles from '../../RoutePass/Controls.scss';

export default function LanguagePicker(props) {
    const [hover, setHover] = useState(false);
    const { setLanguage } = useLanguageContext();

    const handleClick = (languageKey) => {
        setLanguage(languageKey);
    }

    const handleMenuToggle = () => {
        setHover(!hover);
    }

    const renderLanguageOptions = (options) => options.map(({ lang, text, icon }) => (
        <li key={lang} onClick={() => handleClick(lang)}>
            <div>
                <LanguageFlag lang={lang} showText/>
            </div>
        </li>));

    return (
        <div className={`menu-language-picker onTablet ${hover ? 'menu-language-picker-hover' : ''}`}
            onClick={() => handleMenuToggle()}
            >
            <LanguageFlag />
            <ul className="language-dropdown">
                {renderLanguageOptions(LANGUAGES)}
            </ul>
        </div>);
}